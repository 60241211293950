import React from 'react';

const PrivacyPoliticsModal = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  // Define the classnames as variables
  const overlayClass = "fixed inset-0 bg-black bg-opacity-50";
  const modalClass = "bg-white p-12 rounded-lg shadow-lg max-w-2xl w-full text-black overflow-y-auto max-h-[80vh] relative z-10";
  const headingClass = "text-xl font-semibold mb-3";
  const subheadingClass = "text-base font-semibold mt-4 mb-2";
  const paragraphClass = "mb-3 text-sm text-justify leading-relaxed";
  const buttonClass = "bg-neutral-900 text-white px-4 py-2 rounded";
  const listClass = "list-disc list-inside mb-3 text-sm space-y-2 text-justify leading-relaxed";

  return (
    <div className="fixed inset-0 flex justify-center items-center">
      {/* Background overlay */}
      <div className={overlayClass} onClick={onClose}></div>

      {/* Modal content */}
      <div className={modalClass}>
        <h2 className={headingClass}>Política de Privacidad</h2>
        <div className="space-y-4">
          <h3 className={subheadingClass}>1. Introducción</h3>
          <p className={paragraphClass}>
            En SoyYo Digital se tiene como prioridad la privacidad y la protección de datos personales de sus usuarios. En la siguiente política de privacidad se documenta los procesos de recopilación, visualización, publicación y protección de información personal almacenados en la plataforma web y sus derivados.
            Al momento de utilizar los servicios de SoyYo Digital y proporcionar sus datos personales, se aceptan los términos y condiciones de la política de privacidad.
          </p>
          <h3 className={subheadingClass}>2. Recopilación de Datos</h3>
          <p className={paragraphClass}>
            En la plataforma web se recopila la siguiente información personal de los usuarios registrados:
          </p>
          <ul className={listClass}>
            <li><strong>Datos de Registro:</strong> Nombre(s) y Apellido(s), Dirección de Correo Electrónico, Nombre de usuario y Contraseña.</li>
            <li><strong>Datos de Perfil:</strong> Información adicional de los datos de usuario, como foto de perfil, cargo u ocupación, descripción y redes sociales que pertenecen o interactúan con el usuario.</li>
            <li><strong>Datos de Uso:</strong> Enlaces a Redes Sociales de los usuarios, incluyendo páginas y perfiles asociados. Enlaces a correos Electrónicos asociados. Archivos multimedia almacenados en la nube y vinculados al perfil del usuario. Registro y gráfico de métricas de interacción en las redes sociales. Servicios de notificaciones y reservas utilizados por el usuario. Perfiles Públicos y visibles en línea a través de un navegador web.</li>
            <li><strong>Datos Técnicos:</strong> Dirección IP, Localización (registrada desde el acceso al perfil público del usuario, únicamente su previa aprobación de permisos en el navegador).</li>
          </ul>
          <h3 className={subheadingClass}>3. Uso de la Información</h3>
          <p className={paragraphClass}>
            Toda la información personal recopilada en la plataforma es utilizada para los siguientes propósitos:
          </p>
          <ul className={listClass}>
            <li>Proveer y mejorar servicios: Los datos son utilizados para personalizar la experiencia del usuario, gestionar cuentas y proporcionar funcionalidades de edición de perfil.</li>
            <li>Comunicación: Se envían notificaciones por las direcciones de correo electrónico para los usuarios, con el fin de renovar licencias de funcionamiento, cambio de contraseñas o servicio de soporte técnico.</li>
            <li>Seguridad: Garantizar la disponibilidad e integridad de los datos almacenados en la plataforma, así como la confidencialidad de las credenciales de acceso a la página.</li>
            <li>Cumplimiento legal: La plataforma cumple con las obligaciones legales y responde a solicitudes de las autoridades competentes.</li>
          </ul>
          <h3 className={subheadingClass}>4. Datos Compartidos</h3>
          <p className={paragraphClass}>
            La información personal de los usuarios no llegará a ser dispuesta ni compartida con terceros, con excepción de los siguientes casos:
          </p>
          <ul className={listClass}>
            <li>Proveedores de Servicios: Servicios de alojamiento en la nube y dominio en la red tendrán resguardados la información de la página para garantizar su disponibilidad en línea.</li>
            <li>Cumplimiento Legal: Si la ley lo requiere y en respuesta a una orden judicial, se concede con el fin de proteger los derechos, la propiedad o la seguridad de SoyYo Digital, sus usuarios o el público en general la disposición de información personal.</li>
          </ul>
          <h3 className={subheadingClass}>5. Protección de Datos</h3>
          <p className={paragraphClass}>
            La Plataforma de SoyYo Digital implementa las medidas de seguridad necesarias para garantizar la confidencialidad de la información personal de sus usuarios contra el acceso no autorizado, la alteración, divulgación o destrucción de datos. Se garantiza de igual forma la ejecución de procesos de respaldo de información y diagnósticos eventuales de seguridad.
          </p>
          <h3 className={subheadingClass}>6. Derechos de los Usuarios</h3>
          <p className={paragraphClass}>
            Los usuarios que tengan acceso a la plataforma y cuenten con una licencia vigente cuentan con los siguientes derechos sobre su información personal:
          </p>
          <ul className={listClass}>
            <li>Acceso y Actualización: Los usuarios pueden acceder y actualizar su información personal a través de la plataforma para personalizar su perfil y utilizar sus servicios.</li>
            <li>Eliminación: Los usuarios pueden solicitar la eliminación de su cuenta y de la información personal asociada.</li>
            <li>Reseteo de perfil: Los usuarios tienen la potestad de resetear el perfil de su cuenta y realizar respaldo de sus datos e importarlos.</li>
          </ul>
          <h3 className={subheadingClass}>7. Servicio de Cookies</h3>
          <p className={paragraphClass}>
            Con el Uso de Cookies se almacenan datos temporales sobre el perfil de usuario y sus datos personales con el fin de cargar datos de forma eficiente. Entre los más relevantes están los de inicio de sesión y el almacenamiento de los enlaces de perfil.
          </p>
          <h3 className={subheadingClass}>8. Actualizaciones de la Política de Privacidad</h3>
          <p className={paragraphClass}>
            Se mantiene el derecho de actualizar la política de privacidad en caso de que actualizaciones en la plataforma lo requieran, notificando previamente a los usuarios sobre cualquier cambio significativo a través de la plataforma de SoyYo Digital o por correo electrónico. La continuación del uso de la plataforma y sus servicios posterior a la notificación considerará la aceptación de los mismos.
          </p>
        </div>
        <div className="flex justify-end mt-4">
          <button
            onClick={onClose}
            className={buttonClass}
          >
            Cerrar
          </button>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPoliticsModal;
