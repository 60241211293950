import axios from "axios";
import {
  createUserWithEmailAndPassword,
  sendEmailVerification,
  signInWithEmailAndPassword,
} from "firebase/auth";
import { DOMAIN, LOCALHOST_DOMAIN } from "../../../config/Constant";
import { auth } from "../../../firebase/FirebaseUtils";
import { computeSubtotalProducts } from "../../payment_page/Components/OrderSummary";

export async function validateTokenServer(token) {
  const response = await fetch(`${DOMAIN}auth/verifyToken`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `JWT ${token}`,
    },
  });
  if (response.status !== 200) throw Error(await response.json());
  return response.json();
}

export async function createAccountService(data) {
  // Crea una cuenta sin licencia, usada para registrarse y necesaria para
  // acceder a la pasarela de pago
  const { email, password } = data;
  const firebase = await createUserWithEmailAndPassword(
    auth,
    email.trim(),
    password
  );

  const user = firebase.user;
  sendVerificationEmail(user);
  return true;
}

export async function sendVerificationEmail(user) {
  sendEmailVerification(user);
}

export async function loginUserService(data) {
  const { email, password } = data;
  const responseFirebase = await signInWithEmailAndPassword(
    auth,
    email.trim(),
    password
  );

  const user = responseFirebase.user;
  const token = await user.getIdToken(true);

  const response = await fetch(`${DOMAIN}auth/register`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `JWT ${token}`,
    },
  });

  if (response.status !== 200) throw Error(await response.json());

  const userJson = await response.json();
  if (userJson.data) {
    userJson.data.token = token;
  }

  return userJson;
}

export async function createCustomSocialMedia(data, token) {
  const linkObject = new FormData();

  console.log("contenido de data en API", data)

  if (data.title) {
    linkObject.append("title", data.title);
  }
  if (data.url) {
    linkObject.append("url", data.url);
  }
  if (!data.type) {
    console.warn("Usando título como type, implementación incorrecta");
    data.type = data.title.toLowerCase();
  }
  if (data.image) {
    linkObject.append("image", data.image);
  }
  if (data.imageQR) {
    linkObject.append("imageQR", data.imageQR);
  }
  if (data.file) {
    linkObject.append("file", data.file);
  }

  linkObject.append("is_visible", true);
  linkObject.append("type", data.type);

  data.user_id = data.id ?? data.user_id;
  // TODO: Remover todas las partes donde se usa .id en vez de .user_id
  // TODO: Cambiar a typescript :/

  let extra = buildQueryOrNone("user_id", data.user_id);

  const response = await axios.post(
    `${DOMAIN}profile/custom_social_media?${extra}`,
    linkObject,
    {
      headers: {
        Authorization: `JWT ${token}`,
      },
    }
  );

  if (response.status !== 200) throw Error("Error al actualizar la foto");
  return response.data;
}

function buildQueryOrNone(name, value) {
  if (value) {
    return `${name}=${value}`;
  }
  return "";
}

export async function updateUserData(userData, token) {
  let extra = buildQueryOrNone("user_id", userData.user_id);

  const response = await fetch(`${DOMAIN}auth/user?${extra}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `JWT ${token}`,
    },
    body: JSON.stringify({
      username: userData.username,
      phone_number: userData.phone_number,
      first_name: userData.first_name,
      lastname: userData.lastname,
    }),
  });

  if (!response.ok) {
    const errorBody = await response.json();
    throw Error(errorBody.username);
  }

  return response;
}

export async function updateProfile(profileData, token) {
  let extra = buildQueryOrNone("user_id", profileData.user_id);
  const response = await fetch(`${DOMAIN}profile/user?${extra}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `JWT ${token}`,
    },
    body: JSON.stringify(profileData),
  });
  if (!response.ok) throw Error(response);
  return response;
}

export async function getUserStatistics(userId, token) {
  let extra = buildQueryOrNone("user_id", userId);
  const response = await fetch(`${DOMAIN}profile/statistics?${extra}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `JWT ${token}`,
    },
  });

  if (!response.ok) throw Error(response);
  return response.json();
}

export async function updateAvatarPhoto(imageData, token, field, userId) {
  let extra = buildQueryOrNone("user_id", userId);
  const imageForm = new FormData();
  imageForm.append(field ?? "image", imageData);
  const response = await axios.put(
    `${DOMAIN}profile/user?${extra}`,
    imageForm,
    {
      headers: {
        Authorization: `JWT ${token}`,
      },
    }
  );

  if (response.statusText !== "OK")
    throw Error("Error al actualizar la foto de perfil");
  return response.data;
}

export async function requestPaymentMethod(data, products, token) {
  const load = {};
  load.modalidad = "R";
  load.moneda = "BOB";
  load.canal = "W";
  load.descripcion = "Compra producto de SoyYo";
  load.nombreComprador = data.name;
  load.apellidoComprador = data.lastname;
  load.correo = data.email;
  load.telefono = data.phone;
  load.direccionComprador = data.address;
  load.ciudad = data.city;  
  load.extra1 = "";
  load.extra2 = "";
  load.extra3 = "";
  load.urlRespuesta = "http://localhost:3000/payment-completed";
  load.documentoComprador = data?.nit;
  load.verificationCode = data?.verificationCode
  const email = auth.currentUser?.email;
  if (!email) {
    throw Error("Vuelva a iniciar sesión o intentelo más tarde");
  }

  const details = Object.values(products).map((product) => ({
    producto: product.id,
    cantidad: product.quantity,
  }));

  load.detalle = details;

  const price =
    computeSubtotalProducts(Object.values(products)) + (data.deliveryCost ?? 0);
  load.monto = price;
  load.correo = email;
  const response = await fetch(`${DOMAIN}pay/solicitud`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `JWT ${token}`,
    },
    body: JSON.stringify(load),
  });

  const body = await response.json();
  if (!response.ok) {
    throw Error(body);
  }

  return body;
}

export async function consultTransactionById(id, token) {
  const response = await fetch(`${DOMAIN}pay/consulta_extend/${id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `JWT ${token}`,
    },
  });

  const body = await response.json();
  if (!response.ok) {
    throw Error(body);
  }

  return body;
}
