import virtualCard from "../../../assets/landing-page/decoration/plan-virtual2.png";
import basicCard from "../../../assets/landing-page/decoration/plan-simple-modelo.png";
import customCard from "../../../assets/landing-page/decoration/plan-medio-modelo.png";
import fullDesignCard from "../../../assets/landing-page/decoration/plan-avanzado-modelo.png";
import nfctag from "../../../assets/landing-page/decoration/3dtag.png";
import acrylic from "../../../assets/landing-page/decoration/acrílico.jpeg.jpg";
import credentials from "../../../assets/landing-page/decoration/credentials.png";
import booking from "../../../assets/landing-page/decoration/booking.png";


import comboFullDesignCard from "../../../assets/landing-page/decoration/propuesta/plan-fulldesign-modelo.png";
import comboNFCCard from "../../../assets/landing-page/decoration/propuesta/plan-nfc-modelo.png";

export const productCodes = {
    DIGITAL: 5,
    BASIC: 2,
    CUSTOM: 3,
    FULL_DESIGN: 4,
    ACRILICO: 8,
    TAG: 9,
    CREDENTIAL: 10,
}

export const defaultProducts = {
  [productCodes.DIGITAL]: {
    id: productCodes.DIGITAL,
    title: "Tarjeta Digital",
    image: virtualCard,
    description: "Disfruta una tarjeta digital",
    price: 175,
    durationTag: true,
    durationYears: 2,
  },
  
  /* [productCodes.BASIC]: {
    id: productCodes.BASIC,
    title: "Tarjeta Básica",
    image: basicCard,
    description: "Conectate con nuestra tecnología",
    price: 185,
    durationTag: true,
    durationYears: 2,
  }, */
  [productCodes.CUSTOM]: {
    id: productCodes.CUSTOM,
    title: "Tarjeta Custom",
    image: customCard,
    description: "Tu marca, nuestra tecnología",
    price: 285,
    durationTag: true,
    durationYears: 2,
  },
  [productCodes.FULL_DESIGN]: {
    id: productCodes.FULL_DESIGN,
    title: "Tarjeta Full Design",
    image: fullDesignCard,
    description: "Control total sobre tu tarjeta y tu marca",
    price: 340,
    durationTag: true,
    durationYears: 2,
  },
  [productCodes.TAG]: {
    id: productCodes.TAG,
    title: "Tag NFC",
    image: nfctag,
    description: "El accesorio ideal para tu Smartphone",
    price: 40,
  },
  [productCodes.CREDENTIAL]: {
    id: productCodes.CREDENTIAL,
    title: "Portacredenciales",
    image: credentials,
    description: "Lanyard + Credencial Genérico",
    price: 12,
  },

  [productCodes.ACRILICO]: {
    id: productCodes.ACRILICO,
    title: "Acrílico NFC",
    image: acrylic,
    description: "Mayor facilidad en compartir tus datos mediante QR y Tecnología NFC",
    price: 90,
  },
  /* [productCodes.RESERVAS]: {
    id: productCodes.RESERVAS,
    title: "Reservas",
    image: booking,
    description: "Organice y recepcione a sus clientes",
    price: 150,
    durationTag: true,
    durationYears: 1,
  }, */
};
