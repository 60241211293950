import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import LoadingButton from "../../../../components/LoadingButton";
import infoIcon from "../../../../assets/images/social_media_icons/icons8-estrellas-64.png";

const AddContactModal = (props) => {
  const token = useSelector((state) => state.login.token);
  const { setShowModal, userId } = props;
  const titleType = "Principal";
  const imageSource = infoIcon;
  const [isLoading, setIsLoading] = useState(false);
  const { register, handleSubmit, formState } = useForm();
  const { onComplete, onSubmit } = props;

  function closeModal() {
    setShowModal(false);
  }

  function formHasBeenEdited() {
    return formState.isDirty;
  }

  async function handleAddCustomSocialMedia(data) {
    const originalData = {
      title: data.title, // Incluir el nuevo campo 'title' en los datos
      url: data.url, // Incluir el nuevo campo 'url' en los datos
      is_visible: true,
      type: "mainLink",
      customer_user: userId,
      ...data,
    };

    setIsLoading(true);

    try {
      const customSocialMediaResponse = await onSubmit(originalData, token);
      onComplete(customSocialMediaResponse.data);
    } catch (error) {
      console.error("Error al agregar custom social media o crear configuración de contacto:", error);
    } finally {
      setIsLoading(false);
      closeModal();
    }
  }

  return (
    <>
      <p className="font-medium"> {`Enlace ${titleType}`} </p>
      <div className="mt-4 flex items-center">
        <img
          className="max-w-[4rem] rounded-md cursor-pointer"
          alt={`Icono ${titleType}`}
          src={imageSource}
        />
      </div>
      <form
        className="mt-4 flex flex-col text-sm"
        action="POST"
        onSubmit={handleSubmit(handleAddCustomSocialMedia)}
      >
        <div>
          <label htmlFor="title" className="block font-medium">
            Título
          </label>
          <input
            id="title"
            name="title"
            placeholder="Título del enlace"
            className="block w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
            {...register("title")}
          />
        </div>

        <div>
          <label htmlFor="url" className="block font-medium">
            URL
          </label>
          <input
            id="url"
            name="url"
            placeholder="URL del enlace"
            className="block w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
            {...register("url")}
          />
        </div>

        <div className="mt-4 flex items-center justify-end">
          <div className="flex sm:flex-row gap-2">
            <button
              type="button"
              onClick={closeModal}
              className="px-6 py-1 font-medium w-fit rounded-md border border-stone-300 text-stone-900 bg-stone-100"
            >
              Cancelar
            </button>
            <LoadingButton
              type="submit"
              isLoading={isLoading}
              className="px-6 py-1 font-medium w-fit rounded-md border border-transparent transition-colors duration-200 text-stone-100 bg-stone-950 disabled:bg-stone-300 disabled:text-stone-700"
              disabled={!formHasBeenEdited()}
            >
              Agregar
            </LoadingButton>
          </div>
        </div>
      </form>
    </>
  );
};

export default AddContactModal;
