import { cloneElement, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { toast } from "react-toastify";
import { createOrUpdateUserConfigurations } from '../../client_contact/Api/ClientContact';

import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogOverlay,
  DialogPortal,
  DialogTrigger,
} from "@radix-ui/react-dialog";

import "./adminComponentStyles.css";

import { createCustomSocialMediaForMany } from "../Api/AdminServices";
import { LinkCreatorOptions } from "../../home/EditCardComponents/LinkCreationOptions";

import AddCustomLinkButton from '../../home/EditCardComponents/AddCustomLinkButton'





export function MultipleCardEditionModal(props) {

  let { onSocialMediaCreateForAll } =
    props;

  onSocialMediaCreateForAll =
    onSocialMediaCreateForAll ?? createCustomSocialMediaForMany;
  const { employeesSelected } = props;

  const sizeSelected = employeesSelected.size;



  function handleOnComplete(response) {
    toast.success("Enlace creado exitosamente", {
      position: "top-center",
      autoClose: 1500,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  }

  return (
    <DialogPortal>
      <DialogOverlay className="overlay-animation z-40 inset-0 fixed bg-stone-950/25" />
      <DialogContent className="modal-animation z-50 fixed sm:rounded-2xl h-full sm:max-h-[70vh] sm:max-w-screen-sm max-w-[640px] w-full left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 bg-white">
        <div className="px-8 py-4 font-montserrat relative h-full w-full">
          <h2 className="font-medium text-lg">
            Agregar Contenido
            <p className="ml-1 text-sm text-stone-600">
              {sizeSelected} integrante{sizeSelected === 1 ? "" : "s"}{" "}
              seleccionado
              {sizeSelected === 1 ? "" : "s"}
            </p>
          </h2>

          <p className="mt-4 font-medium"> Agregar enlaces </p>
          <div className="overflow-y-scroll h-4/5 sm:h-3/4">
            <LinkCreatorOptions
              onCompleteForAll={handleOnComplete}
              onSubmitForAll={(data, token) => {
                if (data.type === 'contact') {
                  // Verifica si el tipo de enlace es 'contact'
                  const ids = Array.from(employeesSelected);
                  console.log("valor de data", data)
                  // Construye el objeto configurations con el mensaje
                  const configurations = {
                    message: data.message,
                    phone_enabled: data.phone_enabled,
                    email_enabled: data.email_enabled,
                    comment_enabled: data.comment_enabled,
                  };

                  // Construye el objeto final a enviar
                  const requestData = {
                    customer_users: ids,
                    configurations: configurations
                  };

                  // Llama a la función createOrUpdateUserConfigurations con los datos necesarios
                  return createOrUpdateUserConfigurations(requestData, token);
                } else {
                  // Si el tipo de enlace no es 'contact', simplemente crea el enlace social
                  data.ids = Array.from(employeesSelected);
                  return createCustomSocialMediaForMany(data, token);
                }
              }}
            />
          </div>

          <AddCustomLinkButton
            onSubmit={(data, token) => {
              data.ids = Array.from(employeesSelected);
              return onSocialMediaCreateForAll(data, token);
            }}
          />
        </div>
        <DialogClose asChild>
          <button type="button" className="top-5 right-5 absolute">
            <AiOutlineClose
              className="p-[6px] rounded-full box-content hover:bg-stone-100"
              size={20}
            />
          </button>
        </DialogClose>
      </DialogContent>
    </DialogPortal>
  );
}

export function AddSocialMediaButtonTemplate(props) {
  const { icon, title, content } = props;

  const [open, setOpen] = useState(false);


  function renderWithProps() {
    return cloneElement(content, {
      showModal: open,
      setShowModal: setOpen,
    });
  }

  return (
    <Dialog
      open={open}
      onOpenChange={(value) => {
        setOpen(value);
      }}
    >
      <DialogTrigger asChild>
        <button className="py-2 flex rounded-md flex-col justify-center items-center cursor-pointer transition-shadow bg-[rgb(245,245,245)] hover:shadow">
          <img
            className="max-w-[2.5rem]"
            src={icon}
            alt={`Icono de ${title}`}
          />
          <p className="px-3 mt-1 text-sm"> {title} </p>
        </button>
      </DialogTrigger>
      <DialogPortal>
        <DialogOverlay className="fixed z-50 bg-stone-950/10 inset-0" />
        <DialogContent className="z-50 right-1/2 top-1/2 -translate-y-1/2 translate-x-1/2 px-7 pt-4 pb-4 font-montserrat fixed border rounded-md border-stone-300 bg-white shadow-lg">
          {renderWithProps()}
        </DialogContent>
      </DialogPortal>
    </Dialog>
  );
}
