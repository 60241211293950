import { MdVerified } from "react-icons/md";
import nfc from '../../../assets/landing-page/decoration/3dtag.png';

export function ProductsCard(props) {
  const {
    image,
    hoverImage,
    hoverText,
    title,
    description,
    buttonText,
    link,
    durationYears,
    onClickImageOrButton,
    bgColor
  } = props;

  console.log(link)
  console.log(onClickImageOrButton)

  const { durationTag } = props;

  function handleClickImageOrButton(event) {
    if (onClickImageOrButton) {
      onClickImageOrButton();
    }
  }

  const backgroundColor = bgColor ? `bg-${bgColor}` : 'bg-stone-200';
  const borderColor = bgColor ? `border-${bgColor}` : 'border-stone-200';

  return (
    <div className={`relative flex flex-col items-center ${backgroundColor} border ${borderColor} p-2 rounded-lg shadow-lg w-72 h-66`}>
      <button
        onClick={handleClickImageOrButton}
        className="group relative cursor-pointer"
      >
        {/* {title.toLowerCase() === 'tarjeta digital' && (
          <>
          <img
            src={nfc}
            alt="Small Icon"
            className="absolute top-14 right-20 w-20 h-20 z-10 group-hover:block hidden"
          />
          <div className="px-2 py-1 opacity-0 z-10 left-3 bottom-2 font-medium text-xs absolute transition-all group-hover:block group-active:scale-110 group-hover:opacity-100 bg-stone-950 text-stone-50 rounded-md">
            Incluye Tag NFC!
          </div>  
        </>
        )} */}
        
        <div className="relative">
          <img
            src={image}
            aria-label={`comprar producto ${title}`}
            alt={title}
            className="h-28 rounded-md bg-cover relative z-0 transition-transform duration-300 transform-gpu scale-100 group-hover:scale-110"
          />
          <img
            src={hoverImage ?? image}
            alt={title}
            className="h-36 bg-cover absolute top-0 left-0 invisible group-hover:visible"
            style={{ zIndex: -1 }}
          />
        </div>

        <div className="px-2 py-1 opacity-0 right-2 bottom-2 font-medium text-xs absolute transition-all group-hover:block group-active:scale-110 group-hover:opacity-100 bg-stone-950 text-stone-50 rounded-md">
          {hoverText}
        </div>
      </button>

      <h3 className="mt-4 font-semibold text-base"> {title} </h3>
      <p className="text-center text-xs"> {description} </p>

      <div className="h-full"></div>
      <a
        onClick={handleClickImageOrButton}
        href={link}
        className="mt-4 px-4 py-1 rounded-lg bg-stone-950 text-stone-50 cursor-pointer"
      >
        {buttonText}
      </a>

      {durationTag && (
        <div className="left-0 top-0 absolute z-9">
          <div className="px-1 py-1 rounded-md text-base font-semibold flex items-center bg-amber-500 text-white">
            <MdVerified />
            <span className="ml-1 text-white">
              {durationYears} {durationYears === 1 ? 'año' : 'años'}
            </span>
          </div>
        </div>
      )}
    </div>
  );
}
