import React, { useEffect, useState, useMemo } from 'react'
import { useForm } from 'react-hook-form';
import { createDiscount, updateDiscount } from '../../admin_super/Api/discount.api'
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { FaTimes } from 'react-icons/fa';

const DiscountForm = ({ isOpen, onClose, userId, username, discount }) => {
  const [verificationCode, setVerificationCode] = useState('');
  const [storedVerificationCode, setStoredVerificationCode] = useState('');
  const [inputValue, setInputValue] = useState('');

  const copyToClipboard = (text) => {
    const tempTextArea = document.createElement("textarea");
    tempTextArea.value = text;

    document.body.appendChild(tempTextArea);
    tempTextArea.select();
    document.execCommand("copy");

    document.body.removeChild(tempTextArea);

    toast.success("¡Código copiado al portapapeles!", {
      position: "top-center",
      autoClose: 2000,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
    });
  };

  const {
    register,
    watch,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
    submit,
  } = useForm();

  const token = useSelector((state) => state.login.token);

  const onSubmit = handleSubmit(async (data) => {

    try {
      const res = await createDiscount(data, token);
      toast.success("Descuento creado", {
        position: "top-center",
        bodyClassName: "text-sm",
        autoClose: 2000,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        theme: "colored",
      });
      reset();
      onClose();

    } catch (error) {
      console.error('Error al manejar el descuento:', error.message);
      if (error.response && error.response.data) {
        console.error('Detalles del error:', error.response.data);
      }
    }
  });

  const [selectedDiscountType, setSelectedDiscountType] = useState('%');

  const isDateBeforeToday = (date) => {
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() - 1); 
    const inputDate = new Date(date);
    currentDate.setHours(0, 0, 0, 0);
    inputDate.setHours(0, 0, 0, 0);

    return inputDate >= currentDate;
  };

  const isDateAfterInitialDate = (finalDate, initialDate) => {
    const endDate = new Date(finalDate);
    const startDate = new Date(initialDate);

    return endDate >= startDate;
  };

  useEffect(() => {
    if (discount) {
      setValue('customer_user', discount.customer_user);
      setValue('social_media', discount.social_media);
      setValue('discount_type', discount.discount_type);
      setValue('discount_rate', discount.discount_rate);
      setValue('initial_date', discount.initial_date);
      setValue('final_date', discount.final_date);
      setValue('verification_code', discount.verification_code);
      setStoredVerificationCode(discount.verification_code);

      setValue('status', discount.status);
    }
  }, [discount, setValue]);

  const generateVerificationCode = () => {
    const customer_user = document.getElementById('username-display').textContent.substring(0, 4);
    const socialMedia = watch('social_media');
    const discountType = watch('discount_type');
    const discountRate = watch('discount_rate');
    const initialDate = watch('initial_date');
    const initialDateObject = new Date(initialDate);
    const month = initialDateObject.getMonth() + 1; // Sumar 1 ya que los meses comienzan desde 0
    const year = initialDateObject.getFullYear();
    const symbol = discountType === 'percentage' ? 'p' : '$';
    const newVerificationCode = `${customer_user}-${socialMedia}-${symbol}-${discountRate}-${month}-${year}`.toUpperCase();
    setVerificationCode(newVerificationCode);
    setValue('verification_code', newVerificationCode);
  };



  return (
    <>
      {isOpen && (
        <div className="fixed inset-0 z-50 overflow-auto bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-white p-4 max-w-md mx-auto rounded-md shadow-md relative">
            <h3 className='font-bold text-lg mb-3 text-neutral-800'>Crear Descuento</h3>
            <button
              className="absolute top-2 right-2 p-2 text-gray-600 hover:text-gray-800"
              onClick={onClose}>
              <FaTimes />
            </button>
            <form onSubmit={onSubmit} className="max-w-xl mx-auto bg-white p-2 rounded shadow-md grid grid-cols-2 gap-8">
              {/* customer_user */}
              <div className="mb-2">
                <label htmlFor='customer_user' className="block text-sm font-medium text-gray-600">
                  Usuario
                </label>

                <input
                  type='text'
                  value={userId}
                  readOnly
                  onChange={(e) => setInputValue(e.target.value)}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  {...register("customer_user", {
                    required: true,
                    maxLength: {
                      value: 9,
                      message: "Sólo debe ingresarse nombre de usuario"
                    }
                  })}
                />
                <p className='text-neutral-800 font-bold' id='username-display'> {username}</p>
                {errors.customer_user && <span className="text-red-500">{errors.customer_user.message}</span>}
              </div>

              {/* social_media */}
              <div className="mb-2">
                <label htmlFor='social_media' className="block text-sm font-medium text-gray-600">
                  Red Social
                </label>
                <select
                  className="mt-1 p-2 border border-gray-300 rounded-md w-full bg-white text-neutral-700"
                  {...register("social_media", {
                    required: true
                  })}
                  disabled={!!discount}
                >
                  <option value="fb">Facebook</option>
                  <option value="ig">Instagram</option>
                  <option value="yt">Youtube</option>
                  <option value="tw">Twitter</option>
                  <option value="lk">Linkedin</option>
                </select>
                {errors.social_media && <span className="text-red-500">Debe elegir entre las opciones</span>}
              </div>

              {/* discount_type */}
              <div className="mb-2">
                <label htmlFor='discount_type' className="block text-sm font-medium text-gray-600">
                  Tipo de Descuento
                </label>
                <select
                  className="mt-1 p-2 border border-gray-300 rounded-md w-full bg-white text-neutral-700"
                  {...register("discount_type", {
                    required: true
                  })}
                  onChange={(e) => setSelectedDiscountType(e.target.value)}>
                  <option value={"price"}>Monetario (Bs)</option>
                  <option value={"percentage"}>Porcentual (%)</option>
                </select>
                {errors.discount_type && <span className="text-red-500">Debe elegir entre las opciones</span>}
              </div>

              {/* discount_rate */}
              <div className="mb-2">
                <label htmlFor='discount_rate' className="block text-sm font-medium text-gray-600">
                  Monto de Descuento
                </label>
                <div className='relative'>
                  <input
                    type='text'
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    {...register("discount_rate", {
                      required: true,
                      pattern: {
                        value: /^\d+(\.\d{1,2})?$/,
                        message: "El valor debe ser numérico"
                      },
                      validate: (value) => {
                        if (selectedDiscountType === '%' && parseInt(value, 10) > 20) {
                          return "El monto de descuento no debe superar el 20%";
                        }
                        if (selectedDiscountType === 'Bs' && parseInt(value, 10) > 80) {
                          return "El monto de descuento no debe superar los 80 Bs";
                        }
                        return true; // La validación pasa
                      },
                    })}
                    placeholder={` - `}
                  />

                  <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                    <span id='selectedDiscountType' className="text-gray-500">{selectedDiscountType == 'percentage' ? '%' : 'Bs.'}</span>
                  </div>
                </div>
                {errors.discount_rate && <span className="text-red-500">{errors.discount_rate.message}</span>}
              </div>

              {/* initial_date */}
              <div className="mb-2">
                <label htmlFor='initial_date' className="block text-sm font-medium text-gray-600">
                  Fecha Inicial
                </label>
                <input
                  type='date'
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  {...register("initial_date", {
                    required: discount ? false : "Fecha fuera del límite",
                    validate: (value) => {
                      return (
                        (!discount && isDateBeforeToday(value)) || // Aplicar validación solo si no estás en modo de edición
                        "La fecha debe ser anterior al día actual"
                      );
                    },
                  })}
                  disabled={!!discount}
                />
                {(!discount && errors.initial_date) && (
                  <span className="text-red-500">{errors.initial_date.message}</span>
                )}
              </div>

              {/* final_date */}
              <div className="mb-2">
                <label htmlFor='final_date' className="block text-sm font-medium text-gray-600">
                  Fecha Final
                </label>
                <input
                  type='date'
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  {...register("final_date", {
                    required: "Fecha fuera del límite",
                    validate: (value) => {
                      const initialDate = watch('initial_date'); // Obtén el valor de initial_date
                      return isDateAfterInitialDate(value, initialDate) || "La fecha final debe ser posterior a la fecha inicial";
                    },
                  })}
                />
                {errors.final_date && <span className="text-red-500">{errors.final_date.message}</span>}
              </div>

              {/* verification_code */}
              <div className="mb-2">

                <label htmlFor='verification_code' className="block text-sm font-medium text-gray-600">
                  Código de Verificación
                </label>
                <div className="flex items-center">
                  {!discount && (
                    <button
                      className="bg-green-500 hover:bg-green-600 text-white font-bold ml-1 py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                      type="button"
                      onClick={generateVerificationCode}
                    >
                      Generar
                    </button>
                  )}

                  {!discount && (
                    <input
                      type='text'
                      className="shadow appearance-none border rounded py-2 w-auto text-gray-700 leading-tight focus:outline-none focus:shadow-outline verification-code-input"
                      value={verificationCode}
                      readOnly
                      {...register("verification_code", {
                        required: true
                      })}
                    />
                  )}
                </div>


                {errors.verificationCode && <span className="text-red-500">Código no generado</span>}

                {/* Nuevo elemento para mostrar el valor durante la edición */}
                {discount && (

                  <div className="mt-2 flex items-center">
                    <p className="text-gray-600 mr-2">
                      {discount.verification_code}
                    </p>
                    <a
                      className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                      onClick={() => copyToClipboard("https://soyyo.digital/buy?id=1&cupon=" + discount.verification_code)}
                    >
                      Copiar
                    </a>
                  </div>
                )}
              </div>

              <br />

              {/* status */}
              <div className="mb-2">
                <label htmlFor='status' className="flex items-center">
                  <input
                    type='checkbox'
                    className="form-checkbox h-5 w-5 bg-green-500 focus:outline-none focus:shadow-outline mr-2"
                    defaultChecked={true}
                    {...register("status")}
                  />
                  <label className="text-sm text-neutral-700">Activo</label>
                </label>
              </div>

              <button
                type="submit"
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
                Crear Cupón
              </button>

            </form>
          </div>
        </div>
      )}
    </>
  );
};

export { DiscountForm };

