import {
  DialogClose,
  DialogContent,
  DialogOverlay,
  DialogPortal,
} from "@radix-ui/react-dialog";
import { AiOutlineUserDelete } from "react-icons/ai";
import LoadingButton from "../../../components/LoadingButton";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";

import { updateUserAccountData } from "../Api/superUserService";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

export function SimpleEditUserModal(props) {
  const { editUserModal, setEditUserModal, onCompleteEdition } = props;
  const customUser = editUserModal?.custom_user;
  const token = useSelector((state) => state.login.token);

  const [roles, setRoles] = useState({
    is_admin: customUser?.is_admin || false,
    is_superuser: customUser?.is_superuser || false,
    is_sponsor: customUser?.is_sponsor || false,
    is_booking: customUser?.is_booking || false,
    is_sales_manager: customUser?.is_sales_manager || false,
    is_ecommerce: customUser?.is_ecommerce || false,
  });

  const [loading, setLoading] = useState(false);
  
  const [formChanged, setFormChanged] = useState(false); 

  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    if (customUser) {
      setRoles({
        is_admin: customUser.is_admin || false,
        is_superuser: customUser.is_superuser || false,
        is_sponsor: customUser.is_sponsor || false,
        is_booking: customUser.is_booking || false,
        is_sales_manager: customUser.is_sales_manager || false,
        is_ecommerce: customUser.is_ecommerce || false,
      });
      setValue("licencia_id", editUserModal?.licencia?.id);
    }
  }, [customUser, setValue, editUserModal]);

  

  const handleRoleClick = (role) => {
    const currentRoleValue = roles[role];
  
    setRoles((prevRoles) => ({
      ...prevRoles,
      [role]: !prevRoles[role],
    }));
  
    setValue(role, !currentRoleValue);
    setFormChanged(true);
  };
  
  function handleInputChange() {
    setFormChanged(true); 
  }

  function handleEditUser(data) {
    setLoading(true);
  
    data.is_admin = roles.is_admin;
    data.is_superuser = roles.is_superuser;
    data.is_sponsor = roles.is_sponsor;
    data.is_booking = roles.is_booking;
    data.is_sales_manager = roles.is_sales_manager;
    data.is_ecommerce = roles.is_ecommerce;
  
    data.user_id = editUserModal.custom_user.id;
  
    updateUserAccountData(data, token)
      .then((response) => {
        toast.success("Usuario editado exitosamente", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        editUserModal.custom_user = response;
        editUserModal.licencia.id = response.licencia_id;
        onCompleteEdition(editUserModal);
      })
      .catch(() => {
        toast.error("Ocurrió un error al actualizar el usuario", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          theme: "colored",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }
  

  function handleCloseModal() {
    setEditUserModal(false);
    
  }

  

  return (
    <DialogPortal>
      <DialogOverlay className="fixed z-40 bg-stone-950/10 inset-0" />
      <DialogContent className="h-fit sm:max-w-screen-sm max-w-[640px] w-full z-50 right-1/2 top-1/2 -translate-y-1/2 translate-x-1/2 rounded-md fixed border border-stone-300 bg-white shadow-lg">
        <div className="px-7 py-6 h-full w-full font-montserrat">
          <div className="flex items-center">
            <AiOutlineUserDelete size={24} />
            <p className="ml-2 font-medium"> Editar usuario </p>
          </div>

          <form
            onSubmit={handleSubmit(handleEditUser)}
            className="mt-4 gap-4 text-sm flex flex-col"
            onChange={handleInputChange} 
          >
            <div className="grid grid-cols-2 gap-4">
              <div className="col-span-2">
                <p className="text-stone-800">Roles de usuario</p>
                <div className="flex flex-wrap gap-2 mt-2 ">
                  <button
                    type="button"
                    onClick={() => handleRoleClick("is_admin")}
                    className={`px-4 py-2 border rounded-full focus:outline-none ${
                      roles.is_admin ? "bg-red-200 text-red-500 font-semibold" : "bg-gray-200 text-stone-800"
                    }`}
                  >
                    Administrador
                  </button>
                  <button
                    type="button"
                    onClick={() => handleRoleClick("is_superuser")}
                    className={`px-4 py-2 border rounded-full focus:outline-none ${
                      roles.is_superuser ? "bg-green-200 text-green-500 font-semibold" : "bg-gray-200 text-stone-800"
                    }`}
                  >
                    Super Admin
                  </button>
                  <button
                    type="button"
                    onClick={() => handleRoleClick("is_sponsor")}
                    className={`px-4 py-2 border rounded-full focus:outline-none  ${
                      roles.is_sponsor ? "bg-orange-200 text-orange-500 font-semibold" : "bg-gray-200 text-stone-800"
                    }`}
                  >
                    Sponsor
                  </button>
                  <button
                    type="button"
                    onClick={() => handleRoleClick("is_booking")}
                    className={`px-4 py-2 border rounded-full focus:outline-none ${
                      roles.is_booking ? "bg-purple-300 text-purple-500 font-semibold" : "bg-gray-200 text-stone-800"
                    }`}
                  >
                    Reservas
                  </button>
                  <button
                    type="button"
                    onClick={() => handleRoleClick("is_sales_manager")}
                    className={`px-4 py-2 border rounded-full focus:outline-none ${
                      roles.is_sales_manager ? "bg-yellow-200 text-yellow-500 font-semibold" : "bg-gray-200 text-stone-800"
                    }`}
                  >
                    Finanzas
                  </button>
                  <button
                    type="button"
                    onClick={() => handleRoleClick("is_ecommerce")}
                    className={`px-4 py-2 border rounded-full focus:outline-none ${
                      roles.is_ecommerce ? "bg-blue-200 text-blue-500 font-semibold" : "bg-gray-200 text-stone-800"
                    }`}
                  >
                    E-Commerce
                  </button>
                </div>
              </div>

              <div className="col-span-2">
                <label className="text-stone-800">Id de licencia</label>
                <input
                  type="text"
                  {...register("licencia_id", {
                    pattern: {
                      value: /^\d+$/,
                    },
                  })}
                  className={`mt-1 px-4 py-2 border outline-none w-full ${
                    errors.licencia_id ? "border-red-400" : "focus:border-stone-700"
                  }`}
                />
              </div>
            </div>

            <div className="grid grid-cols-2 gap-4">
              <DialogClose asChild>
                <button
                  onClick={handleCloseModal}
                  type="button"
                  className="py-2 col-span-2 sm:col-span-1 rounded-md text-base font-medium bg-stone-200 text-stone-800"
                >
                  Cancelar
                </button>
              </DialogClose>
              <LoadingButton
                className={`py-2 col-span-2 sm:col-span-1 rounded-md text-base font-medium ${
                  formChanged ? 'bg-emerald-600 text-white' : 'bg-gray-300 text-gray-800 cursor-not-allowed'
                }`}
                isLoading={loading}
                color="text-transparent"
                disabled={!formChanged}
              >
                Editar usuario
              </LoadingButton>
            </div>
           
           







          










          </form>
        </div>
      </DialogContent>
      
    </DialogPortal>
  );
}
