import React, { useState } from "react";

import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";

import mapsIcon from "../../../../assets/images/social_media_icons/icons8-google-maps-old-96.png";
import LoadingButton from "../../../../components/LoadingButton";
import { objectToLinkByType } from "../../../../utils/objectToLinkByType";

const AddMapsModal = (props) => {
  const token = useSelector((state) => state.login.token);

  const { setShowModal } = props;

  const titleType = "de ubicación";
  const imageSource = mapsIcon;

  const [isLoading, setIsLoading] = useState(false);

  const { register, handleSubmit, formState } = useForm();

  const { onComplete, onSubmit } = props;

  if (!onComplete) {
    console.log("Se debe pasar parámetro 'onComplete'");
  }
  if (!onSubmit) {
    console.log("Se debe pasar parámetro 'onSubmit'");
  }

  function closeModal() {
    setShowModal(false);
  }

  function formHasBeenEdited() {
    return formState.isDirty;
  }

  async function handleAddCustomSocialMedia(data) {
    data.phone = data.country_code + data.phone;

    data = objectToLinkByType(data, "maps");
    setIsLoading(true);

    onSubmit(data, token)
      .then((response) => {
        onComplete(response.data);
        closeModal();
      })
      .catch((error) => {
        console.log(error);
        window.alert(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  return (
    <>
      <p className="font-medium"> {`Agregar enlace ${titleType}`} </p>
      <div className="mt-4 flex items-center">
        <div className="filter hover:contrast-[0.9]">
          <img
            className="max-w-[4rem] rounded-md cursor-pointer"
            alt={`Icono ${titleType}`}
            src={imageSource}
          />
        </div>
      </div>
      <form
        className="mt-4 flex flex-col text-sm"
        action="POST"
        onSubmit={handleSubmit(handleAddCustomSocialMedia)}
      >
        <label className="text-stone-800 font-medium" htmlFor="address">
          Dirección
        </label>
        <div className="flex mt-1">
          <input
            className="mt-1 px-4 py-2 border outline-none placeholder:text-stone-500"
            type="text"
            id="address"
            {...register("address")}
            placeholder="Dirección"
            required={true}
          />
        </div>

        <div className="mt-4 flex items-center justify-end">
          <div className="flex sm:flex-row gap-2">
            <button
              type="button"
              onClick={closeModal}
              className="px-6 py-1 font-medium w-fit rounded-md border border-stone-300 text-stone-900 bg-stone-100"
            >
              Cancelar
            </button>
            <LoadingButton
              type="submit"
              isLoading={isLoading}
              className="px-6 py-1 font-medium w-fit rounded-md border border-transparent transition-colors duration-200 text-stone-100 bg-stone-950 disabled:bg-stone-300 disabled:text-stone-700"
              disabled={!formHasBeenEdited()}
            >
              Agregar
            </LoadingButton>
          </div>
        </div>
      </form>
    </>
  );
};
export default AddMapsModal;
