import React from "react";

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
  const handlePrevious = () => {
    if (currentPage > 1) {
      onPageChange(currentPage - 1);
    }
  };

  const handleNext = () => {
    if (currentPage < totalPages) {
      onPageChange(currentPage + 1);
    }
  };

  const handlePageChange = (page) => {
    onPageChange(page);
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];
    const maxPageNumbersToShow = 2;
    const startPage = Math.max(1, currentPage - maxPageNumbersToShow);
    const endPage = Math.min(totalPages, currentPage + maxPageNumbersToShow);

    // Agregar "..." al inicio si no se muestra la primera página
    if (startPage > 1) {
      pageNumbers.push(
        <button
          key="first"
          onClick={() => handlePageChange(1)}
          className={`px-2 py-1 mx-1 rounded-md bg-white text-black`}
        >
          1
        </button>
      );
      if (startPage > 2) {
        pageNumbers.push(
          <span key="ellipsis-start" className="mx-1 text-black">
            ...
          </span>
        );
      }
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <button
          key={i}
          onClick={() => handlePageChange(i)}
          className={`px-2 py-1 mx-1 rounded-md ${currentPage === i ? 'bg-neutral-900 text-white' : 'bg-white text-black'}`}
        >
          {i}
        </button>
      );
    }

    // Agregar "..." al final si no se muestra la última página
    if (endPage < totalPages) {
      if (endPage < totalPages - 1) {
        pageNumbers.push(
          <span key="ellipsis-end" className="mx-1 text-black">
            ...
          </span>
        );
      }
      pageNumbers.push(
        <button
          key="last"
          onClick={() => handlePageChange(totalPages)}
          className={`px-2 py-1 mx-1 rounded-md bg-white text-black`}
        >
          {totalPages}
        </button>
      );
    }

    return pageNumbers;
  };

  return (
    <div className="flex space-x-2 justify-center items-center mt-4 text-sm font-medium mb-3">
      <button
        onClick={handlePrevious}
        disabled={currentPage === 1}
        className="px-3 py-1 bg-neutral-900 text-white mx-1 rounded-md"
      >
        anterior
      </button>
      {renderPageNumbers()}
      <button
        onClick={handleNext}
        disabled={currentPage === totalPages}
        className="px-3 py-1 bg-neutral-900 text-white mx-1 rounded-md"
      >
        siguiente
      </button>
    </div>
  );
};

export  {Pagination};
