import React from 'react';

const ConditionsTermsModal = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  // Define the classnames as variables
  const overlayClass = "fixed inset-0 bg-black bg-opacity-50";
  const modalClass = "bg-white p-12 rounded-lg shadow-lg max-w-2xl w-full text-black overflow-y-auto max-h-[80vh] relative z-10";
  const headingClass = "text-xl font-semibold mb-3";
  const subheadingClass = "text-base font-semibold mt-4 mb-2";
  const paragraphClass = "mb-3 text-sm text-justify leading-relaxed";
  const buttonClass = "bg-neutral-900 text-white px-4 py-2 rounded";
  const listClass = "list-disc list-inside mb-3 text-sm space-y-2 text-justify leading-relaxed";

  return (
    <div className="fixed inset-0 flex justify-center items-center">
      {/* Background overlay */}
      <div className={overlayClass} onClick={onClose}></div>

      {/* Modal content */}
      <div className={modalClass}>
        <h2 className={headingClass}>Términos y Condiciones</h2>
        
        <p className='mb-2 font-semibold leading-relaxed'>Términos y Condiciones para el Uso de Tarjetas Digitales de SoyYo Digital:</p>
        
        <p className={paragraphClass}>
          Estos Términos y Condiciones ("Términos") rigen el uso de tarjetas digitales de SoyYo Digital y
          el acceso a la plataforma. Al usar o acceder a los servicios de SoyYo Digital, incluidos los
          servicios relacionados con las tarjetas digitales, usted acepta y se compromete a cumplir con
          estos Términos.
        </p>
        <h3 className={subheadingClass}>1. Descripción del Servicio</h3>
        <p className={paragraphClass}>
          SoyYo Digital ofrece un ecosistema digital que incluye tarjetas físicas personalizadas con
          tecnología NFC, tarjetas digitales, y servicios adicionales de comercio electrónico, registro y
          gestión de eventos, y agendación de citas o reservas. Los usuarios pueden utilizar las tarjetas
          digitales y las funciones de la plataforma para interactuar con su red de contactos y acceder a
          diversas herramientas y servicios.
        </p>
        <h3 className={subheadingClass}>2. Uso de la Plataforma</h3>
        <ul className={listClass}>
          <li>
            <strong>Acceso y Autenticación:</strong> Usted es responsable de mantener la confidencialidad de sus
            credenciales de acceso. No debe compartirlas con terceros.
          </li>
          <li>
            <strong>Licencia Limitada:</strong> Se le concede una licencia limitada para usar la plataforma y las
            tarjetas digitales de acuerdo con estos Términos.
          </li>
          <li>
            <strong>Propósito de Uso:</strong> No debe utilizar la plataforma para fines ilegales o no autorizados ni
            realizar actividades que puedan infringir los derechos de propiedad intelectual de
            terceros.
          </li>
        </ul>
        <h3 className={subheadingClass}>3. Características de las Tarjetas Digitales</h3>
        <ul className={listClass}>
          <li>
            <strong>Identificación de Personal:</strong> Las tarjetas pueden utilizarse para identificar al personal
            de la empresa o de una organización.
          </li>
          <li>
            <strong>Tecnología NFC:</strong> Las tarjetas con tecnología NFC permiten el acceso a información
            digital y a perfiles personales en la plataforma.
          </li>
          <li>
            <strong>Agrupación de Perfiles:</strong> Los usuarios pueden entrelazar información y agrupar perfiles
            en la plataforma para facilitar el acceso a información compartida.
          </li>
        </ul>
        <h3 className={subheadingClass}>4. Responsabilidad y Uso Aceptable</h3>
        <ul className={listClass}>
          <li>
            <strong>Responsabilidad del Usuario:</strong> Usted es responsable de todas las actividades
            realizadas con sus tarjetas digitales o en su cuenta.
          </li>
          <li>
            <strong>Contenido y Comunicación:</strong> No debe usar la plataforma para enviar mensajes
            difamatorios, ofensivos o inapropiados, ni para transmitir malware o virus.
          </li>
        </ul>
        <h3 className={subheadingClass}>5. Propiedad Intelectual</h3>
        <ul className={listClass}>
          <li>
            <strong>Derechos de Propiedad:</strong> Los derechos de propiedad intelectual de la plataforma y las
            tarjetas digitales son propiedad de SoyYo Digital o sus licenciantes.
          </li>
          <li>
            <strong>Uso Restringido:</strong> Usted no puede copiar, modificar ni distribuir el contenido de la
            plataforma sin autorización previa.
          </li>
        </ul>
        <h3 className={subheadingClass}>6. Políticas de Privacidad</h3>
        <p className={paragraphClass}>
          El uso de la plataforma está sujeto a la Política de Privacidad de SoyYo Digital, que regula la
          recopilación y el uso de información personal.
        </p>
        <h3 className={subheadingClass}>7. Modificaciones de los Términos</h3>
        <p className={paragraphClass}>
          SoyYo Digital se reserva el derecho de modificar estos Términos en cualquier momento. Los
          cambios serán efectivos una vez publicados en el sitio web o notificados al usuario.
        </p>
        <h3 className={subheadingClass}>8. Resolución de Disputas</h3>
        <p className="mb-2 text-sm text-justify leading-relaxed">
          Cualquier disputa derivada del uso de las tarjetas digitales o la plataforma se resolverá de
          acuerdo con la legislación aplicable y los procedimientos de resolución de disputas
          establecidos por SoyYo Digital.
        </p>
        {/* <h3 className={subheadingClass}>9. Contacto</h3>
        <p className="mb-4">
          Si tiene alguna pregunta sobre estos Términos y Condiciones, puede comunicarse con
          nosotros a través del correo electrónico [contacto@soyyo.com].
        </p> */}
        <div className="flex justify-end">
          <button
            onClick={onClose}
            className={buttonClass}>
            Cerrar
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConditionsTermsModal;
