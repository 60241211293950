import React, { useState,useEffect } from "react";
import  {BookingTable}  from './Components/BookingTable/BookingTable';
import  {BookingTableAdmin}  from './Components/BookingTable/BookingTableAdmin';

import  {HystoryTable}  from './Components/BookingTable/HystoryTable';
import { useSelector } from 'react-redux';

import { BookingConfigTable } from './Components//BookingConfiguration/BookingConfigTable';
import { BookingConfig } from './Components/BookingConfiguration/BookingConfig';

//import { BookingForm } from './Components/BookingForm/BookingFormOriginal';
import { BookingForm } from './Components/BookingForm/BookingForm';

//import { BookingFormAdmin } from './Components/BookingForm/BookingFormAdminOriginal';
import { BookingFormAdmin } from './Components/BookingForm/BookingFormAdmin';

import {whatsappContactoList, whatsappContacto, whatsappSegment, whatsappCampaign} from '../ria_chat/Api/RiaChat';

export function BookingPage( props) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const initialToken = useSelector((state) => state.login.token);
  const [token, setToken] = useState(initialToken);
  const [activeTab, setActiveTab] = useState("table");
  const { userData, userPersonified } = props;

let id = userData.id;
let is_admin = userData.is_admin;
if (userPersonified) {
  id = userPersonified.id;
  is_admin=userPersonified.is_admin
}

useEffect(() => {
  const fetchData = async () => {
    try {
      const contactList = await whatsappCampaign();
      console.log("Datos de WhatsApp Contact List de RIACHAT:", contactList);
    } catch (error) {
      console.error('Error fetching WhatsApp Contact List:', error);
    }
  };

  fetchData();
}, []);

  return (

    <div>
    <div className="Booking">
      <div className="pt-8 w-full flex flex-col font-montserrat">
      <div className="px-8 flex justify-between items-center mb-4">
          <h2 className="font-medium text-xl min-w-0 whitespace-nowrap overflow-hidden text-ellipsis">
            Reservas
          </h2>
          <button
            onClick={() => setIsModalOpen(true)}
            className="bg-emerald-500 hover:bg-emerald-600 text-white font-bold py-2 px-4 rounded-lg text-xs"
          >
            Crear Reserva
          </button>
        </div>


        <div className="flex flex-col items-center">
        
          <div className="flex text-xs mb-2">
            <button
              onClick={() => setActiveTab("table")}
              className={`mr-3 px-4 py-2 focus:outline-none rounded-lg hover:font-bold  ${
                activeTab === "table"
                  ? "bg-neutral-800 hover:bg-neutral-700 text-white font-bold"
                  : "text-neutral-800 hover:text-neutral-700"
              }`}
            >
              Reservas
            </button>
            {isModalOpen && (
              is_admin ? (
                <BookingFormAdmin id={id} onClose={() => setIsModalOpen(false)} />
              ) : (
                <BookingForm  id={id} onClose={() => setIsModalOpen(false)} />
              )
            )}
            <button
              onClick={() => setActiveTab("config")}
              className={`mr-3 px-4 py-2 focus:outline-none rounded-lg hover:font-bold ${
                activeTab === "config"
                  ? "bg-neutral-800 hover:bg-neutral-700 text-white font-bold"
                  : "text-neutral-800 hover:text-neutral-700"
              }`}
            >
              Mi Horario
            </button>
            <button
              onClick={() => setActiveTab("history")}
              className={`px-4 py-2 focus:outline-none rounded-lg hover:font-bold ${
                activeTab === "history"
                  ? "bg-neutral-800 hover:bg-neutral-700 text-white font-bold"
                  : "text-neutral-800 hover:text-neutral-700"
              }`}
            >
              Historial
            </button>
          </div>

          <div className="mt-2 px-8 w-full">
            {activeTab === "table" && is_admin ? (
              <BookingTableAdmin id={id} />
            ) : (
              activeTab === "table" && <BookingTable id={id} />
            )}
            {activeTab === "config" &&  
              <>
              <BookingConfigTable
                token={token}
                id={id}/>
            
              {/* <BookingConfig 
                id={id}/> */}
            </>
            }
            {activeTab === "history" && <HystoryTable id={id} />}
          </div>

          
        </div>
      </div>
    </div>
  </div>
    
  );
}
