export function objectToLinkByType(data, type) {
  switch (type) {
    case "correo":
      data = emailSocialMedia(data);
      break;
    case "phone":
      data = phoneSocialMedia(data);
      break;
    case "whatsapp":
      data = whatsappSocialMedia(data);
      break;
    case "maps":
      data = mapsSocialMedia(data);
      break;
    case "contact":
      data = contactSocialMedia(data);
      break;
    case "image":
      data = imageSocialMedia(data);
      break;
    default:
      /* console.log(`Type: "${type}" fue incorrecto`); */
      throw Error("Se paso un valor de type no válido");
  }

  data.is_visible = true;
  data.type = type;
  return data;
}

function emailSocialMedia(data) {
  let { email, subject, body } = data;
  let url = `mailto:${email}?subject=${subject} &body=${body}`;
  return {
    title: "Correo electrónico",
    url: url,
  };
}

function phoneSocialMedia(data) {
  let { phone } = data;
  let url = `tel:+${phone}`;
  return {
    title: "Teléfono",
    url: url,
  };
}

function whatsappSocialMedia(data) {
  let { phone_number, message } = data;
  let url = `https://wa.me/${phone_number}?text=${message}`;
  return {
    title: "Whatsapp",
    url: url,
  };
}

function mapsSocialMedia(data) {
  let { address } = data;
  let url = `https://www.google.com/maps?q=${address}`;
  if (address.startsWith('https') || address.startsWith('http') || address.startsWith('www')){
     url = address;
  }
  return {
    title: "Maps",
    url: url,
  };
}

function contactSocialMedia(data) {
  let { address } = data;
  let url = `contacto`;
  return {
    title: "Contacto",
    url: url,
  };
}

function imageSocialMedia(data) {
  return {
    title: data.title,
    url: "/media/custom_social_media/undefined.png",
  };
}
