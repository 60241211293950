import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { PaymentFormInput } from "./PaymentFormInput";
import { PaymentFormInputSelect } from "./PaymentFormInputSelect";
import { auth } from "../../../firebase/FirebaseUtils";
import { AiOutlineShoppingCart } from "react-icons/ai";

import { useRef } from "react";
import { REGEX_PHONE } from "../../../config/RegexConstants";

export function PaymentUserInformation(props) {
  const { user, paymentInfo, setPaymentInfo } = props;

  const formRef = useRef();

  const { register, handleSubmit } = useForm({
    defaultValues: paymentInfo,
    mode: "onChange",
  });

  function handleUserInfoSubmit() {}
  return (
    <div className="flex flex-col">
      <h1 className="font-semibold text-lg">Datos de Facturación</h1>

      <form
        ref={formRef}
        onSubmit={handleSubmit(handleUserInfoSubmit)}
        className="max-w-4xl grid sm:grid-cols-2 gap-x-8 gap-y-3"
      >
        <PaymentFormInput
          label="Nombre"
          type="text"
          register={register("name", {
            onChange: (event) => {
              paymentInfo.name = event.target.value.toUpperCase();;
            },
          })}
          required
        />
        <PaymentFormInput
          label="Apellido"
          type="text"
          register={register("lastname", {
            onChange: (event) => {
              paymentInfo.lastname = event.target.value.toUpperCase();;
            },
          })}
          required
        />
        {/* <PaymentFormInput
          label="Razón Social"
          type="text"
          register={register("razon_social", {
            onChange: (event) => {
              paymentInfo.razon_social = event.target.value;
            },
          })}
          required
        /> */}

        <PaymentFormInput
          label="NIT / CI"
          type="text"
          register={register("nit", {
            onChange: (event) => {
              paymentInfo.nit = event.target.value.toUpperCase();;
            },
          })}
          required
        />

        <PaymentFormInput label="Correo electrónico" type="email" value={auth.currentUser?.email} required disabled />
        <PaymentFormInput
          label="Teléfono"
          type="text"
          register={register("phone", {
            onChange: (event) => {
              paymentInfo.phone = event.target.value.toUpperCase();;
            },
          })}
          pattern={REGEX_PHONE}
          required
        />
        <PaymentFormInputSelect
          label="Ciudad"
          register={register("city", {
            onChange: (event) => {
              const value = event.target.value;
              paymentInfo.city = value;

              if (value !== "Santa Cruz") {
                paymentInfo.deliveryCost = 50;
              } else {
                paymentInfo.deliveryCost = 0;
              }
              setPaymentInfo({ ...paymentInfo });
            },
          })}
          options={["Santa Cruz", "La Paz", "Beni", "Cochabamba", "Tarija", "Chuquisaca", "Oruro", "Potosí", "Pando"]}
        />
        <PaymentFormInput
          label="Dirección"
          type="text"
          placeholder="Av. Corales, Calle Bush, #45"
          register={register("address", {
            onChange: (event) => {
              paymentInfo.address = event.target.value.toUpperCase();;
            },
          })}
          required
        />
      </form>
    </div>
  );
}
