import React, { useState, useRef, useEffect } from "react";
import { HiTrash } from "react-icons/hi";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import axios from "axios";

import { DOMAIN, IMAGE_DOMAIN, LOCALHOST_DOMAIN } from "../../../config/Constant";
import { compressImage, uploadImage } from "../../../utils/uploadImages";
import { addHttpsToUrl } from "../../../utils/addHttpsToUrl";
import OverlayModal from "../../../components/Modals/OverlayModal";

import LoadingButton from "../../../components/LoadingButton";
import LoadingSpinnerV2 from "../../../components/LoadingSpinnerV2";

async function editCustomSocialMedia(token, link) {
  // No se recomienda modificar la visibilidad dentro del modal, puede llevar a un estado inconsistente
  // TODO: Refactorizar en una carpeta de /Services o /Repositories

  const linkObject = {
    title: link.title,
    url: addHttpsToUrl(link.url, link.type),
  };
  const response = await fetch(
    DOMAIN + "profile/custom_social_media/" + link.id,
    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${token}`,
      },
      body: JSON.stringify(linkObject),
    }
  );

  if (!response.ok) throw Error("No se pudo actualizar el enlace");

  return response;
}

async function deleteCustomSocialMedia(token, linkId) {
  // TODO: Refactorizar en una carpeta de /Services o /Repositories
  // Todavía no existe en el backend

  const response = await fetch(
    DOMAIN + "profile/custom_social_media/" + linkId,
    {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${token}`,
      },
    }
  );
  if (!response.ok)
    throw Error(`No se pudo eliminar el enlace, estatus "${response.status}"`);
  return response;
}

async function editCustomSocialMediaImage(token, socialMediaId, imageData) {
  // Utilizo axios para enviar el formData
  const url = DOMAIN + "profile/custom_social_media/" + socialMediaId;

  const data = new FormData();
  data.append("image", imageData);
  const request = await axios.put(url, data, {
    headers: {
      Authorization: `JWT ${token}`,
    },
  });

  return request;
}

const EditSocialLinkModal = (props) => {
  // Permite editar iconos personalizados (custom_social_media)
  // setShowModal:      Permite abrir o cerrar el modal
  // setSocialMedia:    Permite actualizar la lista de enlaces, solo el que se esta editando (titulo, enlace y foto)
  // link:              Información del enlace
  // imageFormData:     Estructura para enviar la imagen a la API
  // imageSource:       La imagen que se muestra dentro del modal

  const { setShowModal, setSocialMedia, deleteSocialMedia, link } = props;

  const { disableImageUpload = false } = props;

  const [imageSource, setImageSource] = useState(
    `${IMAGE_DOMAIN}${link.image}`
  );

  const [imageFormData, setImageFormData] = useState(null);
  const [imageUploaded, setImageUploaded] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const token = useSelector((state) => state.login.token);
  const fileRef = useRef(null);

  const { register, handleSubmit, formState } = useForm({
    defaultValues: link,
  });

  const [deleteLoading, setDeleteLoading] = useState(false);

  const modalRef = useRef(null);

  // TODO: Utilizar Form para validar datos de forma correcta
  //const methods = useForm()

  function closeModal() {
    // TODO: Agregar confirmación al salir
    //¿Estás seguro que deseas salir?
    setShowModal(false);
  }

  function linkHasBeenModified() {
    return formState.isDirty;
  }

  async function handleEditCustomSocialMedia(data) {
    //TODO: Mejorar manejo de errores con un Modal?

    setIsLoading(true);

    if (linkHasBeenModified()) {
      await editCustomSocialMedia(token, data)
        .then((response) => {
          setSocialMedia(data);
        })
        .catch((error) => {
          window.alert(error);
        });
    }

    if (imageUploaded) {
      await editCustomSocialMediaImage(token, link.id, imageFormData)
        .then((request) => {
          setSocialMedia({ ...link, image: request.data.data.image });
        })
        .catch((error) => {
          window.alert(error);
        });
    }

    closeModal();
    setIsLoading(false);
  }

  async function handleImageUpload(event) {
    event.preventDefault();
    const files = event.target.files;
    uploadImage(files, async (reader) => {
      setImageSource(reader.result);
      setImageUploaded(true);

      const file = await compressImage(files);
      setImageFormData(file);
    });
  }

  function handleOpenFilePicker() {
    if (fileRef.current) {
      fileRef.current.click();
    }
  }

  async function handleDeleteCustomSocialMedia() {
    setDeleteLoading(true);
    const responseFuture = deleteCustomSocialMedia(token, link.id);
    responseFuture
      .then(() => {
        deleteSocialMedia();

        closeModal();
      })
      .catch((error) => {
        window.alert(error);
      })
      .finally(() => {
        setDeleteLoading(false);
      });
  }

  useEffect(() => {
    if (modalRef.current) {
    }
  }, []);

  return (
    <>
      <OverlayModal onClick={closeModal} />

      <div
        ref={modalRef}
        className={`absolute top-12 px-7 pt-4 pb-4 -left-2 -right-2 z-20 border rounded-md border-stone-300 bg-white shadow-lg`}
      >
        <p className="font-medium"> Enlace personalizado </p>
        <div className="mt-4 flex items-center">
          <button onClick={handleOpenFilePicker}>
            <img
              className="max-w-[4rem] rounded-md cursor-pointer"
              alt={`Foto de ${link.title}`}
              src={imageSource}
            />
          </button>
          <p
            className="ml-4 text-xs font-medium cursor-pointer text-blue-500 hover:text-blue-600"
            onClick={handleOpenFilePicker}
          >
            Seleccionar imagen ( *png, *jpg, *jpeg )
          </p>
          <input
            ref={fileRef}
            onChange={handleImageUpload}
            className="hidden"
            accept="image/x-png,image/jpeg"
            type="file"
          />
        </div>
        <form
          className="mt-4 flex flex-col text-sm"
          action="PUT"
          onSubmit={handleSubmit(handleEditCustomSocialMedia)}
        >
          <label className="mt-0 text-stone-800 font-medium" htmlFor="title">
            Título
          </label>
          <input
            className="mt-1 px-4 py-2 border outline-none placeholder:text-stone-500"
            type="text"
            id="title"
            name="title"
            placeholder="Título"
            {...register("title")}
            required
            autoFocus
          />

          <label className="mt-4 text-stone-800 font-medium" htmlFor="career">
            Dirección de enlace
          </label>
          <input
            className="mt-1 px-4 py-2 border outline-none placeholder:text-stone-500"
            type="text"
            id="url"
            name="url"
            {...register("url")}
            placeholder="https://mywebsite.com"
            required
          />
          <div className="mt-4 flex items-center justify-between">
            {deleteLoading ? (
              <LoadingSpinnerV2
                size={20}
                className="ml-1"
                fill={"fill-red-600"}
                color="dark:text-red-200"
              />
            ) : (
              <button type="button" onClick={handleDeleteCustomSocialMedia}>
                <HiTrash className="text-red-600 cursor-pointer" size={28} />
              </button>
            )}
            <div className="flex flex-col sm:flex-row gap-2">
              <button
                ref={modalRef}
                onClick={closeModal}
                className="px-6 py-1 font-medium w-fit rounded-md border border-stone-300 text-stone-900 bg-stone-100"
              >
                Cancelar
              </button>

              <LoadingButton
                type="submit"
                isLoading={isLoading}
                className="px-6 py-1 font-medium w-fit rounded-md border border-transparent transition-colors duration-200 text-stone-100 bg-stone-950 disabled:bg-stone-300 disabled:text-stone-700"
                disabled={!(linkHasBeenModified() || imageUploaded)}
              >
                Actualizar
              </LoadingButton>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};
export default EditSocialLinkModal;
