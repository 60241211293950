import { Dialog, DialogTrigger } from "@radix-ui/react-dialog";
import { useCallback, useEffect, useMemo, useState } from "react";
import { AiOutlineSearch } from "react-icons/ai";
import { useSelector } from "react-redux";
import CardContainer from "../../components/CardContainer";
import { getAllCompanies } from "./Api/superUserService";
import { CreateUserModal } from "./Components/CreateUserModal";
import { UsersTableHeader, UserTableRow } from "./Components/UsersTable";
import { UserLoadingTableRow } from "./Components/UsersTableLoading";
import { SimpleEditUserModal } from "./Components/SimpleEditUserModal";
import UsersTablePageSelector from "./Components/UsersTablePageSelector"; // Importa el componente de paginación

export function SuperUsers(props) {
  const token = useSelector((state) => state.login.token);

  const { setUserPersonified } = props;
  const [users, setUsers] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [filters, setFilters] = useState({
    role: 'todos',
    id: '',
    licencia_id: '',
    username: '',
    email: ''
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const [modalId, setModalId] = useState("ABCDEFGH");
  const [modalId2, setModalId2] = useState("ABCDEFGH123");
  const [editUserModal, setEditUserModal] = useState(undefined);

  const [showAdvancedSearch, setShowAdvancedSearch] = useState(false);

  async function loadUsers(page = 1) {
    setIsLoading(true);
    try {
      const { role, id, licencia_id, username, email } = filters;
      const response = await getAllCompanies(token, page, role, username, email, id, licencia_id);

      const usersArray = Object.values(response.data);
      setUsers(usersArray);
      setTotalPages(response.pages);
    } catch (error) {
      console.error("Error fetching companies:", error);
    } finally {
      setIsLoading(false);
    }
  }

  function setUserByIndex(index) {
    return (user) => {
      users[index] = user;
      console.log("user",user)
      setUsers([...users]);
    };
  }

  function renderUserResults(results) {
    if (!results) {
      return null;
    }
    const list = results.map((user, index) => (
      <UserTableRow
        key={user.custom_user.id}
        setUser={setUserByIndex(index)}
        userData={user}
        setUserPersonified={setUserPersonified}
        setEditUserModal={setEditUserModal}
        index={index}
        onSelectUser={() => null}
      />
    ));
    return list;
  }

  const handlePageChange = (page) => {
    setCurrentPage(page);
    loadUsers(page);
  };

  function handleSimpleEditUserModalOnComplete(user) {
    const updateUser = setUserByIndex(user.index);
    updateUser(user);
  }

  function reloadFormState(open) {
    if (open) return;
    setModalId(Math.random().toString(36).substring(2, 10));
  }
  function reloadFormState2(open) {
    if (!open) {
      setEditUserModal(undefined);
      setModalId2(Math.random().toString(36).substring(2, 10));
    }
  }

  const toggleAdvancedSearch = () => {
    setShowAdvancedSearch(!showAdvancedSearch);
  };

  useEffect(() => {
    loadUsers(currentPage);
  }, [currentPage, filters]);

  let memoizedUserRows = useMemo(
    () => renderUserResults(users),
    [isLoading, users]
  );

  return (
    <div className="pt-8 w-full flex flex-col font-montserrat">
      <div className="px-8 flex justify-between">
        <h2 className="font-medium text-xl min-w-0 whitespace-nowrap overflow-hidden text-ellipsis">
          Usuarios de SoyYo
        </h2>
        <Dialog key={modalId} onOpenChange={reloadFormState}>
          <DialogTrigger asChild>
            <button className="px-3 py-1 shadow-md font-medium rounded-md hover:bg-emerald-500/90 bg-emerald-500 text-white">
              Agregar usuario
            </button>
          </DialogTrigger>
          <CreateUserModal />
        </Dialog>
      </div>
      <div className=" px-8 w-full">
        <div className="flex flex-wrap gap-x-6 gap-y-2 items-end">
          <div className="flex flex-wrap gap-x-6 gap-y-2 items-end">
            <div className="w-full sm:max-w-[24rem]">
              <label className="text-xs text-gray-700"> Username </label>
              <input
                className="text-xs pl-6 pr-4 py-1 placeholder-stone-400 outline-none rounded-md w-full sm:min-w-[24rem] border border-gray-300 focus:border-gray-600 bg-white"
                type="text"
                placeholder="Buscar por Username"
                value={filters.username}
                onChange={(e) => setFilters({ ...filters, username: e.target.value })}
              />
            </div>
          <button
            className="px-3 py-1 text-xs text-white font-medium rounded-md bg-emerald-500 hover:bg-emerald-600 "
            onClick={() => loadUsers(1)}>
            Buscar
          </button>
          </div>
          <div className="flex flex-col">
            <label className="text-xs text-gray-700"> Tipo de usuario </label>
            <select
              id="role-search"
              className="px-2 py-[6px] rounded-md bg-white border border-gray-300 focus:border-gray-600 text-xs"
              value={filters.role}
              onChange={(e) => setFilters({ ...filters, role: e.target.value })}
            >
              <option value="todos"> Todos </option>
              <option value="is_active"> Activo </option>
              <option value="is_inactive"> No Activo </option>
              <option value="is_superuser"> Superusuario </option>
              <option value="is_admin"> Administrador </option>
              <option value="is_sponsor"> Descuentos </option>
              <option value="is_booking"> Reservas </option>
              <option value="is_sales_manager"> Finanzas </option>
              <option value="is_ecommerce"> Ecommerce </option>
            </select>
          </div>
          <button
            className="px-3 py-1 text-xs text-white font-medium rounded-md bg-neutral-800 hover:bg-neutral-700"
            onClick={toggleAdvancedSearch}
          >
            {showAdvancedSearch ? 'Ocultar' : 'Búsqueda avanzada'}
          </button>

          {showAdvancedSearch && (
            <div id="collapse-input" className="flex flex-wrap gap-x-2 gap-y-2 mt-1">
              <div className="flex flex-col w-24 sm:max-w-[6rem]">
                <label className="text-xs text-gray-700"> ID </label>
                <input
                  className="text-xs pl-6 pr-4 py-1 placeholder-stone-400 outline-none rounded-md border border-gray-300 focus:border-gray-600 bg-white w-full"
                  type="text"
                  placeholder="ID"
                  value={filters.id}
                  onChange={(e) => setFilters({ ...filters, id: e.target.value })}
                  maxLength="4"
                />
              </div>
              <div className="flex flex-col w-24 sm:max-w-[6rem]">
                <label className="text-xs text-gray-700"> Licencia ID </label>
                <input
                  className="text-xs pl-6 pr-4 py-1 placeholder-stone-400 outline-none rounded-md border border-gray-300 focus:border-gray-600 bg-white w-full"
                  type="text"
                  placeholder="Licencia ID"
                  value={filters.licencia_id}
                  onChange={(e) => setFilters({ ...filters, licencia_id: e.target.value })}
                  maxLength="4"
                />
              </div>
              <div className="flex flex-col w-48 sm:max-w-[24rem]">
                <label className="text-xs text-gray-700"> Email </label>
                <input
                  className="text-xs pl-6 pr-4 py-1 placeholder-stone-400 outline-none rounded-md border border-gray-300 focus:border-gray-600 bg-white w-full"
                  type="text"
                  placeholder="Email"
                  value={filters.email}
                  onChange={(e) => setFilters({ ...filters, email: e.target.value })}
                />
              </div>
            </div>
          )}
          
        </div>
      </div>

      <div className="mt-4 px-6 gap-x-8 gap-y-6 grid grid-cols-1">
        <CardContainer className="px-6 py-4 shadow-md">
          <div className="mt-2 overflow-x-auto overflow-y-auto h-screen relative sm:rounded-lg">
            <table className="w-full text-sm text-left text-stone-800">
              <Dialog
                key={modalId2}
                open={editUserModal}
                onOpenChange={reloadFormState2}
              >
                <SimpleEditUserModal
                  editUserModal={editUserModal}
                  onCompleteEdition={handleSimpleEditUserModalOnComplete}
                  setEditUserModal={setEditUserModal}
                />
              </Dialog>
              <UsersTableHeader />
              <tbody>
                {isLoading ? <UserLoadingTableRow /> : memoizedUserRows}
              </tbody>
            </table>
          </div>
          <UsersTablePageSelector
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
          />
        </CardContainer>
      </div>
    </div>
  );
}