import CardContainer from "../../components/CardContainer";

import { AiOutlineSearch } from "react-icons/ai";

import { useEffect, useState } from "react";
import { LoadingTableRow } from "./Components/EmployeeTableLoading";
import { EditAdminEmployee } from "./EditAdminEmployee";
import {getBookingSettings} from '../booking_page/Api/Booking'
import {BookingSwitch} from '../booking_page/Components/BookingSwitch';


import "./Components/adminComponentStyles.css";
import {
  TableHeader,
  TableRow,
  TableRowNoResults,
} from "./Components/EmployeesTable";

import { MultipleSelectedToast } from "./Components/MultipleSelectedToast";
import { getAllEmployees } from "./Api/AdminServices";
import { useSelector } from "react-redux";
import { getAllCompanyAreas } from "../../utils/analyticsUtils";
import { Pagination } from './Components/Pagination';

export function AdminCompany(props) {
  const token = useSelector((state) => state.login.token);

  const [filters, setFilters] = useState({
    name: "",
    area: "",
  });

  const [employees, setEmployees] = useState();

  const [isLoading, setIsLoading] = useState(true);

  const [employeeSelected, setEmployeeSelected] = useState();

  const [multipleSelected, setMultipleSelected] = useState(new Set());

  const [bookingSettings, setBookingSettings] = useState(null);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const { userPersonified, user, userId } = props;

  function loadTableData(page=1, searchValue= "") {
    let id = undefined;
    if (props.userPersonified) {
      id = props.userPersonified.id;
    }

    setIsLoading(true);
  getAllEmployees(token, page, searchValue, id)
    .then((response) => {
      setEmployees(response.data);
      setIsLoading(false);
      setTotalPages(response.pages);
      setCurrentPage(page);
    })
    .catch((error) => {
      console.error(error);
    });
      
      getBookingSettings(userId, token)
        .then((bookingSettings) => {
          setBookingSettings(bookingSettings); // Guardar las configuraciones en el estado
        })
        .catch((error) => {
          console.error("Error fetching booking settings:", error);
        });
  }

  function setStateEmployeeById(userId) {
    return (user) => {
      for (let [index, employee] of employees.entries()) {
        if (employee.id === userId) {
          employees[index] = user;
        }
      }
      setEmployees([...employees]);
    };
  }

  useEffect(() => {
    loadTableData(currentPage, filters.name);
  }, [currentPage, filters.name]);

  return (
    <div className="pt-8 w-full flex flex-col font-montserrat bg-[rgb(240,240,240)]">
      {!employeeSelected ? (
        <>
        <AdminCompanyTable
          filters={filters}
          setFilters={setFilters}
          setEmployeeSelected={setEmployeeSelected}
          setMultipleSelected={setMultipleSelected}
          setEmployees={setEmployees}
          multipleSelected={multipleSelected}
          isLoading={isLoading}
          employees={employees}
          user={user}
          adminId={userId}
        />
        <Pagination
      currentPage={currentPage}
      totalPages={totalPages}
      onPageChange={(page) => {
        setIsLoading(true);
        setCurrentPage(page);
      }}
    />
    </>
      ) : (
        <EditAdminEmployee
          employee={employeeSelected}
          setEmployee={setEmployeeSelected}
          setStateEmployeeById={setStateEmployeeById}
          bookingSettings={bookingSettings} 
        />
      )}
    </div>
  );
}

function AdminCompanyTable(props) {
  const { setEmployeeSelected, setMultipleSelected, setEmployees, user, token, userId } =
    props;
  const { multipleSelected, isLoading, adminId, employees } = props;

  const { filters, setFilters } = props;


  function setEmployeesAreaBySelected(area) {
    for (let employee of employees) {
      if (multipleSelected.has(employee.id)) {
        employee.rubro = area;
      }
    }
    setEmployees([...employees]);
  }

  function filterEmployees(filters, employees) {
    if (!employees) {
      return undefined;
    }
    const name = filters.name.toLowerCase();
    const area = filters.area;

    if (area !== "") {
      employees = employees.filter((e) => e.rubro === area);
    }

    return employees.filter((e) => e.username.toLowerCase().includes(name));
  }

  function handleSelectFilterByArea(value) {
    setFilters({ ...filters, area: value });
  }

  function handleCheckBoxEmployee(user, index) {
    const key = user.id;
    return (checked) => {
      if (checked) {
        multipleSelected.add(key);
      } else {
        multipleSelected.delete(key);
      }
      setMultipleSelected(new Set(multipleSelected));
    };
  }

  const filteredEmployees = filterEmployees(filters, employees);

  function renderEmployeeResults(employees) {
    const filtered = filteredEmployees;
    if (employees.length === 0 && filtered.length === 0) {
      return ""; // No tienes ningun integrante en tu empresa
    }
    if (filtered.length === 0) {
      return <TableRowNoResults />; // No se encontro ningun resultado
    }
    return filtered.map((user, index) => (
      <TableRow
        user={user}
        key={index}
        isLoggedUser={adminId === user.id}
        setEmployeeSelected={setEmployeeSelected}
        checked={multipleSelected.has(user.id)}
        onClickCheckBox={handleCheckBoxEmployee(user, index)}
      />
    ));
  }

  function getCompanyAreas() {
    return [...getAllCompanyAreas(employees || [])];
  }

  return (
    <div>

      
      {/* <div className="mb-4 px-6 gap-x-8 gap-y-6 grid grid-cols-1">
        <CardContainer className="px-6 py-4 shadow-md">
            <h2 className="font-medium">Módulos</h2>
            {user.is_booking && <BookingSwitch token={token} userId={adminId} />}
        </CardContainer>
      </div> */}


      <div className="px-8 flex flex-wrap gap-y-2 justify-between">
        <h2 className="pr-12 font-medium text-xl min-w-0 whitespace-nowrap overflow-hidden text-ellipsis">
          Integrantes
        </h2>
      </div>
      <div className="mt-4 px-8 w-full">
        <form onSubmit={(e) => e.preventDefault()} className="w-full sm:w-fit">
          <div className="flex flex-wrap gap-x-6 gap-y-2 items-end">
            <div className="w-full sm:max-w-[24rem]">
              <label className="text-sm text-gray-700"> Buscar </label>
              <div className="relative">
              <input
          className="pl-6 pr-4 py-1 placeholder-stone-500 outline-none rounded-md w-full border border-gray-300 focus:border-gray-600 bg-white"
          type="text"
          value={filters.name}
          onChange={(event) =>
            setFilters({ ...filters, name: event.target.value })
          }
          placeholder="Buscar"
        />
                <AiOutlineSearch className="absolute left-1 top-1/2 -translate-y-1/2 cursor-pointer  text-stone-400" />
              </div>
            </div>

            <FilterCombobox
              areas={getCompanyAreas()}
              onSelectOption={handleSelectFilterByArea}
            />
          </div>
        </form>
      </div>

      <div className="mt-4 px-6 gap-x-8 gap-y-6 grid grid-cols-1">
        <CardContainer className="px-6 py-4 shadow-md">
          <h2 className="font-medium">
            Integrantes de {user.username}
            {multipleSelected.size !== 0 ? ` (${multipleSelected.size})` : ""}
          </h2>
          <div className="mt-2 overflow-x-auto sm:rounded-lg">
            <table className="text-sm text-left w-full text-stone-800">
              <TableHeader
                setEmployees={setEmployees}
                employees={employees}
                setMultipleSelected={setMultipleSelected}
                filteredEmployees={filteredEmployees}
                multipleSelected={multipleSelected}
              />
              <tbody>
                {isLoading ? (
                  <LoadingTableRow />
                ) : (
                  renderEmployeeResults(employees)
                )}
              </tbody>
            </table>
            <MultipleSelectedToast
              open={multipleSelected.size > 0}
              employeesSelected={multipleSelected}
              setEmployeesAreaBySelected={setEmployeesAreaBySelected}
              setMultipleSelected={setMultipleSelected}
            />
          </div>
        </CardContainer>
      </div>
    </div>
  );
}

function FilterCombobox(props) {
  const { areas, onSelectOption } = props;

  return (
    <div className="flex flex-col">
      <label className="text-sm text-gray-700"> Por rubro </label>
      <select
        onChange={(event) => {
          onSelectOption(event.target.value);
        }}
        className={`px-2 py-[6px] rounded-md bg-white border border-gray-300 foc focus:border-gray-600`}
      >
        <option value=""> Todos </option>
        {areas.map((area, index) => (
          <option key={index} value={area}>
            {area}
          </option>
        ))}
      </select>
    </div>
  );
}