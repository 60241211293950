import React, { useState, useRef, useEffect } from "react";
import { HiTrash } from "react-icons/hi";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import axios from "axios";

import { DOMAIN, IMAGE_DOMAIN, LOCALHOST_DOMAIN } from "../../../config/Constant";
import { compressImage, uploadImage } from "../../../utils/uploadImages";
import OverlayModal from "../../../components/Modals/OverlayModal";
import { FaPhone, FaComment, FaEnvelope } from "react-icons/fa";

import {getClientContactConfigDetails, updateClientContactConfig, deleteClientContactConfig} from '../../client_contact/Api/ClientContact';


import LoadingButton from "../../../components/LoadingButton";
import LoadingSpinnerV2 from "../../../components/LoadingSpinnerV2";

async function editCustomSocialMedia(token, link) {
  // No se recomienda modificar la visibilidad dentro del modal, puede llevar a un estado inconsistente
  // TODO: Refactorizar en una carpeta de /Services o /Repositories

  const linkObject = {
    title: link.title,
    message: link.message,
    phone: link.phone,
    email: link.email,
    comment: link.comment
  };

  
  const response = await fetch(
    DOMAIN + "profile/custom_social_media/" + link.id,
    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${token}`,
      },
      body: JSON.stringify(linkObject),
    }
  );

  if (!response.ok) throw Error("No se pudo actualizar el enlace");

  return response;
}

async function deleteCustomSocialMedia(token, linkId) {
  // TODO: Refactorizar en una carpeta de /Services o /Repositories
  // Todavía no existe en el backend

  const response = await fetch(
    DOMAIN + "profile/custom_social_media/" + linkId,
    {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${token}`,
      },
    }
  );
  if (!response.ok)
    throw Error(`No se pudo eliminar el enlace, estatus "${response.status}"`);
  return response;
}

async function editCustomSocialMediaImage(token, socialMediaId, imageData) {
  // Utilizo axios para enviar el formData
  const url = DOMAIN + "profile/custom_social_media/" + socialMediaId;

  const data = new FormData();
  data.append("image", imageData);
  const request = await axios.put(url, data, {
    headers: {
      Authorization: `JWT ${token}`,
    },
  });

  return request;
}





























const EditContactLinkModal = (props) => {
  // Permite editar iconos personalizados (custom_social_media)
  // setShowModal:      Permite abrir o cerrar el modal
  // setSocialMedia:    Permite actualizar la lista de enlaces, solo el que se esta editando (titulo, enlace y foto)
  // link:              Información del enlace
  // imageFormData:     Estructura para enviar la imagen a la API
  // imageSource:       La imagen que se muestra dentro del modal

  

  const { setShowModal, setSocialMedia, deleteSocialMedia, link,hasContactCustomMedia,setHasContactCustomMedia } = props;

  const { disableImageUpload = false } = props;

  const [imageSource, setImageSource] = useState(
    `${IMAGE_DOMAIN}${link.image}`
  );

  const [imageFormData, setImageFormData] = useState(null);
  const [imageUploaded, setImageUploaded] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const token = useSelector((state) => state.login.token);
  const fileRef = useRef(null);


  const { register, handleSubmit, formState } = useForm({
    defaultValues: link,
  });

  const [deleteLoading, setDeleteLoading] = useState(false);

  const modalRef = useRef(null);

  const [buttonModified, setButtonModified] = useState(false);
  const [formData, setFormData] = useState({
    title: "",
    message: "",
  });
  useEffect(() => {
    async function fetchData() {
      try {
        const data = await getClientContactConfigDetails(link.customer_user, token);
        setFormData((prevFormData) => ({
          ...prevFormData,
          message: data.message,
          phone_enabled: data.phone_enabled,
          email_enabled: data.email_enabled,
          comment_enabled: data.comment_enabled
        }));
      } catch (error) {
        console.error("Error al obtener detalles de la configuración de contacto:", error);
      }
    }
    fetchData();
  }, [link.customer_user, token]);
  

  // TODO: Utilizar Form para validar datos de forma correcta
  //const methods = useForm()

  function closeModal() {
    // TODO: Agregar confirmación al salir
    //¿Estás seguro que deseas salir?
    setShowModal(false);
  }

  function linkHasBeenModified() {
    return formState.isDirty;
  }

  async function handleEditCustomSocialMedia(data) {
    //TODO: Mejorar manejo de errores con un Modal?
    setIsLoading(true);

    if (linkHasBeenModified()) {
      await editCustomSocialMedia(token, {
        ...data,
        phone: buttons.phone,
        email: buttons.email,
        comment: buttons.comment
      })
        .then((response) => {
          setSocialMedia(data);
        })
        .catch((error) => {
          //window.alert(error);
        });
  
      const updatedData = {
        ...data,
        customer_user: link.customer_user, 
      };
      await updateClientContactConfig(link.customer_user, updatedData, token)
        .then((response) => {
        })
        .catch((error) => {
          window.alert(error);
        });
    }

    if (imageUploaded) {
      await editCustomSocialMediaImage(token, link.id, imageFormData)
        .then((request) => {
          setSocialMedia({ ...link, image: request.data.data.image });
        })
        .catch((error) => {
          window.alert(error);
        });
    }

    closeModal();
    setIsLoading(false);
  }


  async function handleImageUpload(event) {
    event.preventDefault();
    const files = event.target.files;
    uploadImage(files, async (reader) => {
      setImageSource(reader.result);
      setImageUploaded(true);

      const file = await compressImage(files);
      setImageFormData(file);
    });
  }

  function handleOpenFilePicker() {
    if (fileRef.current) {
      fileRef.current.click();
    }
  }

  async function handleDeleteCustomSocialMedia() {
    setDeleteLoading(true);
    const responseFuture = deleteCustomSocialMedia(token, link.id);
    responseFuture
      .then(async () => {
        await deleteClientContactConfig(link.customer_user, token);
        deleteSocialMedia();
        closeModal();
        setHasContactCustomMedia(false);
      })
      .catch((error) => {
        window.alert(error);
      })
      .finally(() => {
        setDeleteLoading(false);
      });
  }
  

  useEffect(() => {
    if (modalRef.current) {
    }
  }, []);

 
  const [buttons, setButtons] = useState({
    phone: true,
    email: true,
    comment: true,
  });
  const toggleButton = (button) => {
    setButtons({
      ...buttons,
      [button]: !buttons[button],
    });
  
    // Actualizar el estado correspondiente en el formData
    setFormData((prevFormData) => ({
      ...prevFormData,
      [`${button}_enabled`]: !prevFormData[`${button}_enabled`],
    }));
  
    // Establecer buttonModified como true cuando se cambia un botón
    setButtonModified(true);
  };
  function linkHasBeenModified() {
    return formState.isDirty || buttonModified;
  }

  return (
    <>
      <OverlayModal onClick={closeModal} />

      <div
        ref={modalRef}
        className={`absolute top-12 px-7 pt-4 pb-4 -left-2 -right-2 z-20 border rounded-md border-stone-300 bg-white shadow-lg`}
      >
        <p className="font-medium"> Enlace personalizado </p>
        <div className="mt-4 flex items-center">
          <button onClick={handleOpenFilePicker}>
            <img
              className="max-w-[4rem] rounded-md cursor-pointer"
              alt={`Foto de ${link.title}`}
              src={imageSource}
            />
          </button>
          <p
            className="ml-4 text-xs font-medium cursor-pointer text-blue-500 hover:text-blue-600"
            onClick={handleOpenFilePicker}
          >
            Seleccionar imagen ( *png, *jpg, *jpeg )
          </p>
          <input
            ref={fileRef}
            onChange={handleImageUpload}
            className="hidden"
            accept="image/x-png,image/jpeg"
            type="file"
          />
        </div>
        <form
          className="mt-4 flex flex-col text-sm"
          action="PUT"
          onSubmit={handleSubmit(handleEditCustomSocialMedia)}
        >
          <label className="mt-0 text-stone-800 font-medium" htmlFor="title">
            Título
          </label>
          <input
            className="mt-1 px-4 py-2 border outline-none placeholder:text-stone-500"
            type="text"
            id="title"
            name="title"
            placeholder="Título"
            {...register("title")}
            required
            autoFocus
          />



<textarea
  className="mt-1 px-4 py-2 border outline-none placeholder:text-stone-500"
  id="message"
  name="message"
  placeholder="Message"
  {...register("message")}
  defaultValue={formData.message}
  required
  autoFocus
/>

       



<div className=" mb-2 mt-2">
          <label className=" font-medium ">
            Solicitar datos adicionales:
          </label>
          <span className="text-neutral-500 mb-1">
            Seleccione los datos que desea recibir del usuario que busca
            contactarlo
          </span>
          <div className=" flex justify-center gap-4">
            <div>
              <div
                className={`flex items-center justify-center cursor-pointer rounded-full w-16 h-16 text-2xl text-white focus:outline-none ${
                  formData.phone_enabled
                    ? "bg-blue-500 hover:bg-blue-400"
                    : "bg-red-500 hover:bg-red-400"
                }`}
                onClick={() => toggleButton("phone")}
              >
                <FaPhone />
              </div>
              <span
                className={`text-sm font-medium text-center ${
                  formData.phone_enabled ? "text-blue-500" : "text-red-500"
                }`}
              >
                {formData.phone_enabled ? "Activo" : "Inactivo"}
              </span>
            </div>

            <div>
              <div
                className={`flex items-center justify-center cursor-pointer rounded-full w-16 h-16 text-2xl text-white focus:outline-none ${
                  formData.email_enabled
                    ? "bg-blue-500 hover:bg-blue-400"
                    : "bg-red-500 hover:bg-red-400 "
                }`}
                onClick={() => toggleButton("email")}
              >
                <FaEnvelope />
              </div>
              <span
                className={`text-sm font-medium text-center ${
                  formData.email_enabled ? "text-blue-500" : "text-red-500"
                }`}
              >
                {formData.email_enabled ? "Activo" : "Inactivo"}
              </span>
            </div>

            <div>
              <div
                className={`flex items-center justify-center cursor-pointer rounded-full w-16 h-16 text-2xl text-white focus:outline-none ${
                  formData.comment_enabled
                    ? "bg-blue-500 hover:bg-blue-400"
                    : "bg-red-500 hover:bg-red-400 "
                }`}
                onClick={() => toggleButton("comment")}
              >
                <FaComment />
              </div>
              <span
                className={`text-sm font-medium text-center ${
                  formData.comment_enabled ? "text-blue-500" : "text-red-500"
                }`}
              >
                {formData.comment_enabled ? "Activo" : "Inactivo"}
              </span>
            </div>
          </div>
        </div>


          <div className="mt-4 flex items-center justify-between">
            {deleteLoading ? (
              <LoadingSpinnerV2
                size={20}
                className="ml-1"
                fill={"fill-red-600"}
                color="dark:text-red-200"
              />
            ) : (
              <button type="button" onClick={handleDeleteCustomSocialMedia}>
                <HiTrash className="text-red-600 cursor-pointer" size={28} />
              </button>
            )}
            <div className="flex flex-col sm:flex-row gap-2">
              <button
                ref={modalRef}
                onClick={closeModal}
                className="px-6 py-1 font-medium w-fit rounded-md border border-stone-300 text-stone-900 bg-stone-100"
              >
                Cancelar
              </button>

              <LoadingButton
                type="submit"
                isLoading={isLoading}
                className="px-6 py-1 font-medium w-fit rounded-md border border-transparent transition-colors duration-200 text-stone-100 bg-stone-950 disabled:bg-stone-300 disabled:text-stone-700"
                disabled={!(linkHasBeenModified() || imageUploaded)}
                onClick={() => {
                  if (link.id) {
                    handleEditCustomSocialMedia(formData);
                  } else {
                    console.error("No se puede ejecutar handleEditCustomSocialMedia porque link.id no tiene valor");
                  }
                }}
              >
                Actualizar
              </LoadingButton>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default EditContactLinkModal;
