import SoyYoName from "../../../assets/landing-page/brand/soyyo-name.png";
import { useState } from 'react';
import LogoKetrawe from "../../../assets/landing-page/footer/logo-semillas-ketrawe.png";
import amchan from '../../../assets/landing-page/sponsors/amcham.png';
import cainco from '../../../assets/landing-page/sponsors/cainco.png';
import { scrollToById } from "../Services/Utils/scrollToById";
import ConditionsTermsModal from './ConditionsTermsModal';
import PrivacyPoliticsModal from "./PrivacyPoliticsModal";

export function Footer() {
  const [isTermsModalOpen, setIsTermsModalOpen] = useState(false);
  const [isPrivacyModalOpen, setIsPrivacyModalOpen] = useState(false);

  const openTermsModal = () => setIsTermsModalOpen(true);
  const closeTermsModal = () => setIsTermsModalOpen(false);

  const openPrivacyModal = () => setIsPrivacyModalOpen(true);
  const closePrivacyModal = () => setIsPrivacyModalOpen(false);

  return (
    <footer className="pt-12 pb-4 w-full text-stone-50 bg-stone-950">
      <div className="grid grid-cols-1 gap-y-8 md:grid-cols-5">
        {/* Company Info */}
        <div className="col-span-2">
          <img src={SoyYoName} alt="SoyYo" className="max-w-[8rem] mx-10" />
          <p className="px-8 mt-4 tracking-wide">
            SoyYo Digital es una empresa enfocada a la innovación y desarrollo sostenible en Bolivia.
          </p>

          <div className="flex flex-col md:flex-row px-8 gap-4 mt-4 md:mt-4">
            <p
              className="text-sm text-center text-neutral-400 hover:text-white underline cursor-pointer"
              onClick={openTermsModal}
            >
              Ver Términos y Condiciones
            </p>
            <p
              className="text-sm text-center text-neutral-400 hover:text-white underline cursor-pointer"
              onClick={openPrivacyModal}
            >
              Ver Políticas de Privacidad
            </p>
          </div>
        </div>

        {/* Navigation */}
        <div className="gap-3 px-8 w-fit flex-col flex">
          <p className="font-semibold mb-1">Navegar</p>
          <button onClick={scrollToById("start")} className="text-left">
            Inicio
          </button>
          <button onClick={scrollToById("benefits")} className="text-left">
            Beneficios
          </button>
          <button onClick={scrollToById("products")} className="text-left">
            Productos
          </button>
          <button onClick={scrollToById("steps")} className="text-left">
            Obten tu tarjeta
          </button>
        </div>

        {/* Social Media */}
        <div className="flex flex-col gap-3 px-8">
          <p className="font-semibold mb-1">Nuestras redes</p>
          <a href="https://www.facebook.com/profile.php?id=100092543589746" target="_blank" rel="noreferrer">
            Facebook
          </a>
          <a href="https://www.instagram.com/soyyo.digital/" target="_blank" rel="noreferrer">
            Instagram
          </a>
          <a href="https://api.whatsapp.com/send/?phone=59169108791&text=Hola,+me+gustaría+saber+más+sobre+Soy+Yo+y+sus+tarjetas+digitales/" target="_blank" rel="noreferrer">
            Whatsapp
          </a>
        </div>

        {/* Partners */}
        <div className="flex flex-col gap-2 px-8">
          <p className="font-semibold mb-2">Formamos parte de:</p>
          <a target="_blank" href="https://www.cainco.org.bo/" rel="noreferrer">
            <img
              className="max-w-[6rem] max-h-[6rem] w-full h-auto"
              src={cainco}
              alt="Logo Cainco"
              style={{ maxWidth: '100%', maxHeight: '100%', transform: 'scale(0.9)' }}
            />
          </a>
          <a target="_blank" href="https://amchambolivia.com/amcham_es/" rel="noreferrer">
            <img
              className="max-w-[6rem] max-h-[6rem] w-full h-auto"
              src={amchan}
              alt="Logo Amcham"
              style={{ maxWidth: '100%', maxHeight: '100%', transform: 'scale(0.9)' }}
            />
          </a>
        </div>
      </div>

      {/* Terms and Privacy */}
      <div className="mt-8 pt-4">
        <div className="flex flex-col md:flex-row justify-center items-center">
          <p className="text-sm text-center mr-4">
            Copyright &copy; 2024 SoyYo Digital
          </p>
          
        </div>
      </div>

      <ConditionsTermsModal isOpen={isTermsModalOpen} onClose={closeTermsModal} />
      <PrivacyPoliticsModal isOpen={isPrivacyModalOpen} onClose={closePrivacyModal} />
    </footer>
  );
}
