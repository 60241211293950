import React, { useState, useEffect } from 'react';
import { getAllProducts, createNewProduct, updateProductById, deleteProductById } from './Api/products';
import { useSelector } from 'react-redux';
import { CreateProductModal } from './Components/CreateProductModal';
import { ConfirmationProductModal } from './Components/ConfirmationProductModal';
import CardContainer from "../../components/CardContainer";
import { FaPencilAlt, FaTrash, FaEye, FaEyeSlash } from 'react-icons/fa';

export function ProductsTable() {
  const initialToken = useSelector((state) => state.login.token);
  const [token, setToken] = useState(initialToken);
  const [products, setProducts] = useState([]);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [editingProduct, setEditingProduct] = useState(null);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [productToDelete, setProductToDelete] = useState(null);
  const [newProductData, setNewProductData] = useState({
    title: '',
    price: '',
    description: '',
  });
  const [productCount, setProductCount] = useState(0);


  const fetchProducts = async () => {
    try {
      const response = await getAllProducts(token);
      setProducts(response.data);
      setProductCount(response.data.length);
    } catch (error) {
      console.error('Error al obtener productos', error);
    }
  };

  const handleCreateOrUpdateProduct = async () => {
    await fetchProducts();
    setIsCreateModalOpen(false);
    setEditingProduct(null);
  };

  const handleEditClick = (product) => {
    setEditingProduct(product);
    setIsCreateModalOpen(true);
    setNewProductData({
      title: product.title,
      price: product.price,
      description: product.description,
      image: null,
    });
  };

  const handleConfirmDelete = async () => {
    try {
      if (productToDelete) {
        await deleteProductById(token, productToDelete.id);
        fetchProducts(); // Actualizar la lista de productos después de la eliminación
      }
    } catch (error) {
      console.error('Error al eliminar el producto', error);
    } finally {
      setProductToDelete(null);
      setIsConfirmModalOpen(false);
    }
  };

  const handleCancelDelete = () => {
    setProductToDelete(null);
    setIsConfirmModalOpen(false);
  };

  const handleDeleteProduct = (product) => {
    setProductToDelete(product);
    setIsConfirmModalOpen(true);
  };

  useEffect(() => {
    fetchProducts();
  }, []);

  // Ordenar productos por el campo `order` en orden ascendente
  const sortedProducts = products.slice().sort((a, b) => a.order - b.order);

  return (
    <div className="pt-8 w-full font-montserrat">
      <div className="px-8 flex justify-between items-center">
        <h2 className="font-medium text-xl truncate">
          Productos
        </h2>
        <button
          onClick={() => {
            setEditingProduct(null); // Asegúrate de que editingProduct esté vacío
            setIsCreateModalOpen(true);
          }}
          className="bg-emerald-500 py-2 px-4 rounded-lg text-white text-sm font-semibold hover:bg-emerald-400"
        >
          Crear Producto
        </button>
      </div>

      <div className="mt-4 px-6 gap-8 grid grid-cols-1">
        <CardContainer className="px-6 py-4 shadow-md">
        <div className="w-full overflow-x-auto">
          <table className="min-w-full border-collapse text-sm">
            <thead>
              <tr className="bg-neutral-800 text-white">
                <th className="border-b-2 p-3">Orden</th>
                <th className="border-b-2 p-3">Imagen</th>
                <th className="border-b-2 p-3">Nombre</th>
                <th className="border-b-2 p-3">Precio</th>
                <th className="border-b-2 p-3">Estado</th>
                <th className="border-b-2 p-3">Acciones</th>
              </tr>
            </thead>
            <tbody>
              {sortedProducts.map((product) => (
                <tr
                  key={product.id}
                  className={`${
                    product.status === 0
                      ? 'bg-neutral-400 text-neutral-600'
                      : 'hover:bg-blue-400 hover:text-white'
                  }`}
                >
                  <td className="p-3 text-center">{product.order}</td>
                  <td className="p-3 flex justify-center">
                    <img
                      src={product.image}
                      alt={product.title}
                      className="w-12 h-12 object-cover bg-white rounded-lg sm:w-10 sm:h-10"
                    />
                  </td>
                  <td className="p-3">
                    {product.title} <span className="block text-neutral-500">id:{product.id}</span>
                  </td>
                  <td className="p-3 text-center">Bs. {product.price}</td>
                  <td className="p-3 text-center">
                    <button
                      className={`p-2 text-lg rounded-xl text-white text-center ${
                        product.status === 1 ? 'bg-green-500' : 'bg-red-500'
                      }`}
                      onClick={(e) => {
                        e.stopPropagation();
                        // Lógica adicional para cambiar estado
                      }}
                    >
                      {product.status === 1 ? <FaEye /> : <FaEyeSlash />}
                    </button>
                  </td>
                  <td className="p-3 space-x-2 text-center flex justify-center items-center">
                    <button
                      className="bg-blue-500 p-2 text-white rounded hover:bg-blue-600"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleEditClick(product);
                      }}
                    >
                      <FaPencilAlt />
                    </button>
                    <button
                      className="bg-red-500 p-2 text-white rounded hover:bg-red-600"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleDeleteProduct(product);
                      }}
                    >
                      <FaTrash />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        </CardContainer>
      </div>

      <CreateProductModal
        isOpen={isCreateModalOpen}
        onRequestClose={() => setIsCreateModalOpen(false)}
        token={token}
        onCreateOrUpdateProduct={handleCreateOrUpdateProduct}
        editingProduct={editingProduct}
        productCount={productCount} 
      />

      <ConfirmationProductModal
        isOpen={isConfirmModalOpen}
        onRequestClose={handleCancelDelete}
        onConfirm={handleConfirmDelete}
        onCancel={handleCancelDelete}
        message="¿Estás seguro de que deseas eliminar este producto?"
      />
    </div>
  );
}
