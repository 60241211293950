import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";

import QRCode from "react-qr-code";

import CardContainer from "../../components/CardContainer";
import { MdContentCopy } from "react-icons/md";
import { updateUserData } from "../admin/Api/UserServices";
import { REGEX_USERNAME } from "../../config/RegexConstants";
import { CuponesUsuario } from "./Components/DiscountList";
import {BookingAgenda} from './Components/BookingAgenda';
import { auth } from "../../firebase/FirebaseUtils";

import { DOMAIN, IMAGE_DOMAIN } from "../../config/Constant";

function UserProfile(props) {
  const { userData, setUserData, userId = undefined } = props;

  return (
    <div className="pt-8 w-full flex flex-col font-montserrat">
      <div className="px-8 flex justify-between">
        <h2 className="font-medium text-xl min-w-0 whitespace-nowrap overflow-hidden text-ellipsis">Perfil</h2>
      </div>

      <UserProfileBody userData={userData} setUserData={setUserData} userId={userId} />
    </div>
  );
}

export function UserProfileBody(props) {
  const token = useSelector((state) => state.login.token);

  const { userData, setUserData } = props;

  let { onSubmitUpdateUser, onComplete } = props;
  onSubmitUpdateUser ??= updateUserData;

  const [showCopyTooltip, setShowCopyTooltip] = useState(false);
  const [userDataConst, setUserDataConst] = useState({
    username: userData.username,
    email: userData.email,
    phone_number: userData.phone_number,
    first_name: userData.first_name,
    lastname: userData.lastname,
  });

  const { register, handleSubmit, formState, reset } = useForm({
    defaultValues: userDataConst,
  });

  function handleCopyDiscount() {
    const inputElement = document.getElementById("cupon_id");

    if (inputElement) {
      inputElement.select(); // Selecciona el contenido del input
      document.execCommand("copy"); // Copia el contenido al portapapeles
      inputElement.blur(); // Quita el foco del input
    }
  }

  function handleCopyLink() {
    const link = `https://www.soyyo.digital/u/${userData.username}`;

    navigator.clipboard
      .writeText(link)
      .then((response) => {
        setShowCopyTooltip(true);

        setTimeout(() => {
          setShowCopyTooltip(false);
        }, 1000);
      })
      .catch((error) => {
        console.error(error);
      });
  }
  function handleUpdateProfile(data) {
    delete data.email;
    data.user_id = userData.id;
    onSubmitUpdateUser(data, token)
      .then((response) => {
        setUserDataConst({ ...userData, ...data });
        setUserData({ ...userData, ...data });
        onComplete?.({ ...userData, ...data });
        handleReloadFormData(data);
      })
      .catch((error) => {
        window.alert(error);
      });
  }

  function handleReloadFormData(data) {
    if (data) {
      reset({ ...data });
      return;
    }
    reset({ ...userDataConst });
  }

  function formHasBeenEdited() {
    return formState.isDirty;
  }
  return (
    <div className="mt-4 px-6 gap-x-8 gap-y-6 grid grid-cols-1 lg:grid-cols-2">
      <CardContainer className="px-6 py-4 shadow-md">
        <h2 className="font-medium"> Información personal </h2>
        <form className="flex flex-col text-sm" onSubmit={handleSubmit(handleUpdateProfile)}>
          <label className="mt-3 text-xs text-stone-800" htmlFor="username">
            Enlace de tu tarjeta
          </label>
          <input
            id="username"
            type="text"
            {...register("username")}
            required
            pattern={REGEX_USERNAME}
            className="border-b-2 outline-none px-2 pb-1 border-b-emerald-300 invalid:border-b-red-400"
          ></input>
          <label className="mt-4 text-xs text-stone-800" htmlFor="phone_number">
            Teléfono
          </label>
          <input
            className="border-b-2 outline-none px-2 pb-1 border-b-emerald-300 invalid:border-b-red-400"
            id="phone_number"
            type="text"
            {...register("phone_number")}
            required
            pattern="^\d+|^\+\d+\s?\d+"
          ></input>

          <div className="ml-auto mt-5 flex gap-2">
            <button
              onClick={() => handleReloadFormData()}
              type="button"
              className="px-2 sm:px-8 py-1 font-medium w-fit rounded-md border border-stone-300 text-stone-600 bg-stone-100"
            >
              Cancelar
            </button>

            <button
              type="submit"
              disabled={!formHasBeenEdited()}
              className="px-2 sm:px-8 py-1 font-medium w-fit rounded-md text-stone-100 bg-stone-950 disabled:bg-stone-300 disabled:text-stone-700"
            >
              Actualizar
            </button>
          </div>
        </form>
      </CardContainer>
      <CardContainer className="px-6 py-4 text-sm shadow-md">
        <h2 className="font-medium text-base"> Comparte tu tarjeta </h2>

        <p className="mt-3 text-sm"> Enlace de tu tarjeta </p>

        <div className="mt-1 px-2 py-1 relative border rounded-md text-stone-600">
          <p className="select-all">{`https://www.soyyo.digital/u/${userDataConst.username}`}</p>

          <div className="absolute top-1/2 -translate-y-1/2 right-2">
            <MdContentCopy className=" cursor-pointer text-stone-800" onClick={handleCopyLink} />
            <div
              className={`${
                !showCopyTooltip ? "hidden" : ""
              } px-2 py-1 -top-1 left-1/2 -translate-x-1/2 -translate-y-full absolute rounded-3xl bg-stone-800 text-stone-100`}
            >
              ¡Listo!
            </div>
          </div>
        </div>
        <QRCode className="mt-4 mb-1 mx-auto h-32" value={`https://www.soyyo.digital/u/${userData.public_id}`} />
      </CardContainer>
      {userData?.is_sponsor ? (
        <CardContainer className="px-6 py-4 shadow-md">
          <h2 className="font-medium text-base"> Tus cupones activos </h2>

          <p className="mt-3 text-sm">Cupones</p>

          <ul className="mt-1">
            <li className="mb-2">
              <CuponesUsuario userID={userData.id} />
            </li>
           
          </ul>
        </CardContainer>
      ) : null}{" "}

      {/* <CardContainer className="px-6 py-4 text-sm shadow-md">
        <BookingAgenda></BookingAgenda>
      </CardContainer> */}
    </div>
  );
}

export default UserProfile;
