import React, { useEffect, useState } from 'react';
import { getAllDiscounts, updateDiscount } from '../../admin_super/Api/discount.api';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { DiscountDetails } from './DiscountEdit';
import { DiscountSearch } from './DiscountSearch';
import CardContainer from '../../../components/CardContainer';
import { FaLink, FaEye } from 'react-icons/fa';


export function DiscountList() {
  const initialToken = useSelector((state) => state.login.token);
  const [token, setToken] = useState(initialToken);
  const [discounts, setDiscounts] = useState([]);
  const [selectedDiscount, setSelectedDiscount] = useState(null);
  const [expandedDiscount, setExpandedDiscount] = useState(null);
  const [sortBy, setSortBy] = useState('initial_date');
  const [sortOrder, setSortOrder] = useState('desc');

  const handleSort = (columnName) => {
    if (sortBy === columnName) {
      // Si ya estamos ordenando por esta columna, cambia el tipo de orden
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      // Si estamos cambiando a una nueva columna, establece el nuevo criterio de orden
      setSortBy(columnName);
      setSortOrder('asc');
    }
  };

  useEffect(() => {
    async function loadDiscounts() {
      try {
        const res = await getAllDiscounts(token);
        setDiscounts(res.data);
      } catch (error) {
        console.error('Error al cargar descuentos:', error.message);
      }
    }
    loadDiscounts();
  }, [token]);

  const handleCollapseClick = (discountId) => {
    setSelectedDiscount(discounts.find((discount) => discount.id === discountId));
    setExpandedDiscount((prevId) => (prevId === discountId ? null : discountId));
  };

  const handleSaveChanges = async (updatedData) => {
    try {
      const allUpdatedData = {
        ...selectedDiscount,
        ...updatedData,
      };

      await updateDiscount(token, selectedDiscount.id, allUpdatedData);

      const updatedDiscounts = await getAllDiscounts(token);
      setDiscounts(updatedDiscounts.data);

      toast.success('Descuento actualizado exitosamente!');
    } catch (error) {
      console.error('Error al actualizar el descuento:', error.message);
    }
  };

  const calculateDaysRemaining = (initialDate, finalDate) => {
    const today = new Date();
    const startDate = new Date(initialDate);
    const endDate = new Date(finalDate);

    let daysRemaining = Math.ceil((endDate.getTime() - today.getTime()) / (1000 * 60 * 60 * 24));
    daysRemaining = Math.max(0, daysRemaining);

    return daysRemaining;
  };

  const isFinalDatePassed = (finalDate) => {
    const today = new Date();
    const finalDateObj = new Date(finalDate);
    return finalDateObj < today;
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.toLocaleString('default', { month: 'short' });
    const year = date.getFullYear();

    return `${day} ${month} ${year}`;
  };

  return (
    <div className="mt-2 px-6 gap-x-8 gap-y-6 grid grid-cols-1 ">
      <DiscountSearch/>
      <CardContainer className="px-6 py-4 shadow-md">
        <div className="container mx-auto mt-2 p-6 bg-white rounded-md shadow-md overflow-x-auto" >
          {discounts.length > 0 ? (
              <table className="min-w-full border border-neutral-300 text-xs">
                <thead>
                  <tr className='bg-neutral-800 text-white'>
                    <th className="py-2 px-4 border-b">ID</th>
                    <th className="py-2 px-4 border-b">Usuario</th>
                    <th className="py-2 px-4 border-b">Descuento</th>
                    <th className="py-2 px-4 border-b cursor-pointer" onClick={() => handleSort('initial_date')}>
                      {sortBy === 'initial_date' && <p className="ml-1">{sortOrder === 'asc' ? 'Fechas ▲' : 'Fechas ▼'}</p>}
                    </th>
                    <th className="py-2 px-4 border-b">Código</th>
                    <th className="py-2 px-4 border-b">Vigencia</th>
                    <th className="py-2 px-4 border-b">Estado</th>
                    <th className="py-2 px-4 border-b">Acciones</th>
                  </tr>
                </thead>
                <tbody>
                  {discounts
                    .slice()
                    .sort((a, b) => {
                      if (sortBy === 'initial_date') {
                        const dateA = new Date(a.initial_date);
                        const dateB = new Date(b.initial_date);
                        return sortOrder === 'asc' ? dateA - dateB : dateB - dateA;
                      }
                      return 0;
                    })
                    .map((discount) => (
                      <React.Fragment key={discount.id}>
                        <tr className="hover:bg-gray-100 text-center"  onClick={() => handleCollapseClick(discount.id)}>
                          <td className="py-2 px-4 border-b">{discount.id}</td>
                          <td className="py-2 px-4 border-b">{discount.customer_user}</td>
                          <td className="py-2 px-4 border-b">
                            {discount.discount_rate} {discount.discount_type === 'price' ? 'Bs.' : '%'}
                          </td>
                          <td className="py-2 px-4 border-b text-sm">
                            <div className="flex items-center">
                              <div className=" p-1 mr-2">
                                {formatDate(discount.initial_date)}
                              </div>
                              <span className="text-gray-500">-</span>
                              <div className=" p-1 ml-2">
                                {formatDate(discount.final_date)}
                              </div>
                            </div>
                          </td>

                          <td className="py-2 px-4 border-b">{discount.verification_code}</td>
                          <td className="py-2 px-4 border-b text-center ">{calculateDaysRemaining(discount.initial_date, discount.final_date)} días</td>
                          
                          <td className="py-2 px-4 text-xs font-semibold">
                            {discount.status && !isFinalDatePassed(discount.final_date) ? (
                              <div className="bg-green-500 text-white w-full py-1 rounded">Activo</div>
                            ) : (
                              <div className="bg-red-500 text-white w-full py-1 rounded">Inactivo</div>
                            )}
                            
                          </td>
                          <td className="py-2 px-4 border-b text-sm ">
                          
                            <button
                              className="text-white hover:bg-purple-400 bg-purple-500 p-1 py-1 px-3 rounded relative mr-1 group"
                              onClick={(e) => {
                                e.stopPropagation();
                                const verificationCode = discount.verification_code;
                                const linkToCopy = `https://www.soyyo.digital/buy?cupon=${verificationCode}`;
                                const tempElement = document.createElement('textarea');
                                tempElement.value = linkToCopy;
                                document.body.appendChild(tempElement);
                                tempElement.select();
                                tempElement.setSelectionRange(0, 99999);
                                document.execCommand('copy');
                                document.body.removeChild(tempElement);
                                toast.success('Enlace copiado exitosamente');
                              }}
                            >
                              <FaLink className="" />
                              <span className="opacity-0 group-hover:opacity-100 transition-opacity text-xs bg-neutral-700 text-white py-2 px-2 rounded-md absolute z-10 -right-2 transform -translate-x-1/2 mb-2">
                                {discount.verification_code}
                              </span>
                            </button>
                            <button
                            className="text-white hover:bg-blue-400 bg-blue-500 p-1 py-1 px-3  rounded"
                            onClick={(e) => {
                              e.stopPropagation(); // Evita la propagación al contenedor de la fila
                              handleCollapseClick(discount.id);
                            }}
                          >
                              <FaEye className="" />
                            </button>
                          </td>
                        </tr>
                        {expandedDiscount === discount.id && selectedDiscount !== null && (
                          <DiscountDetails discount={selectedDiscount} onSave={handleSaveChanges} />
                        )}
                      </React.Fragment>
                    ))}
                </tbody>
              </table>
          ) : (
            <p className="text-gray-500">No hay cupones disponibles.</p>
          )}
        </div>
      </CardContainer>
    </div>
  );
}
