import React, { useState } from "react";
import { useSelector } from "react-redux";

import CardContainer from "../../components/CardContainer";
import { updateEmail } from "firebase/auth";
import { DOMAIN } from "../../config/Constant";
import { auth } from "../../firebase/FirebaseUtils";
import { useForm } from "react-hook-form";

import { RequestCredentialModal } from "../../components/Modals/RequestCredentialModal";

const UserSettings = (props) => {
  const token = useSelector((state) => state.login.token);

  const { userData, setUserData, logout } = props;
  const { register, handleSubmit, formState, reset, getValues } = useForm({
    defaultValues: { email: auth.currentUser.email },
  });


  const [showCredentialModal, setShowCredentialModal] = useState(false);


  function handleReloadFormData() {
    reset();
  }

  function formHasBeenEdited() {
    return formState.isDirty;
  }

  function resetForm(data) {
    reset(data);
  }

  /* async function updateUser(data) {
    const response = await fetch(DOMAIN + "auth/user", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${token}`,
      },
      body: JSON.stringify(data),
    });
    return await response.json();
  } */
  async function updateUser(data) {
    const response = await fetch(`${DOMAIN}auth/user?user_id=${userData.id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${token}`,
      },
      body: JSON.stringify(data),
    });
    return await response.json();
  }
  

  async function handleChangeEmail() {
    handleRequestCredential();
  }

  function updateUserEmail() {
    const data = getValues();

    updateEmail(auth.currentUser, data.email)
    .then( (responseFirebase) => {
      const responseAPI = updateUser(data);
      responseAPI
        .then((response) => {
          if(response.detail === "Email not Verified") {
            window.alert("El nuevo correo electrónico debe ser verificado, se debe cerrar sesión")
            logout()
          }
          const user = { ...userData, email: data.email };
          setUserData(user);
          resetForm(user);
        })
        .catch((error) => {
          console.error(error);
          window.alert("Error actualizando correo en servidor");
        });
    })
    
    .catch( (error) => {
      console.error(error);
      window.alert(`Error actualizando correo: ${error.code}`)
    });
  }

  async function handleRequestCredential() {
    setShowCredentialModal(true);
  }

  return (
    <div className="pt-8 w-full flex flex-col font-montserrat">
      <div className="px-8 flex justify-between">
        <h2 className="font-medium text-xl min-w-0 whitespace-nowrap overflow-hidden text-ellipsis">
          Opciones
        </h2>
      </div>

      <div className="mt-4 px-6 gap-x-8 gap-y-6">
        <CardContainer className="px-6 py-4 shadow-md w-full">
          <h2 className="font-medium"> Cambiar correo electrónico </h2>
          {showCredentialModal ? (
            <RequestCredentialModal
              showModal={showCredentialModal}
              setShowModal={setShowCredentialModal}
              onAuthenticated={updateUserEmail}
            />
          ) : (
            <></>
          )}
          <form
            onSubmit={handleSubmit(handleChangeEmail)}
            className="mt-2 w-full"
          >
            <input
              className="px-4 py-2 border outline-none w-full"
              id="email"
              type="email"
              {...register("email")}
              required
            ></input>

            <div className="mt-5 flex gap-2">
              <button
                onClick={handleReloadFormData}
                type="button"
                className="ml-auto px-2 sm:px-8 py-1 font-medium w-fit rounded-md border border-stone-300 text-stone-600 bg-stone-100"
              >
                Cancelar
              </button>

              <button
                type="submit"
                disabled={!formHasBeenEdited()}
                className="px-2 sm:px-8 py-1 font-medium w-fit rounded-md text-stone-100 bg-stone-950 disabled:bg-stone-300 disabled:text-stone-700"
              >
                Actualizar
              </button>
            </div>
          </form>

          <div className="mt-16 font-medium text-sm">
            <button
              className="px-6 py-2 rounded-lg bg-stone-900 text-stone-50"
              onClick={logout}
            >
              Cerrar sesión
            </button>
          </div>
        </CardContainer>
      </div>
    </div>
  );
};

export default UserSettings;
