import React, { useState, useEffect, useRef } from 'react';
import { FaCheck, FaBan, FaEye } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { getAllBookings, useUser, updateBooking, getBookingSettings, ConfBookingListView } from '../../Api/Booking';
import { BookingEdit } from './BookingEdit';
import { ButtonsForm } from './ButtonsForm';
import ConfirmationModal from '../ConfirmationModal';
import { getEmployeesList } from '../../../admin/Api/AdminServices';
import { IMAGE_DOMAIN } from '../../../../config/Constant';

const BookingTableAdmin = (props) => {
  const initialToken = useSelector((state) => state.login.token);
  const [token, setToken] = useState(initialToken);
  const [tableData, setTableData] = useState([]);
  const [expandedRow, setExpandedRow] = useState(null);
  const [selectedBooking, setSelectedBooking] = useState(null);
  const [kids, setKids] = useState("");
  const [teen, setTeen] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [time_bet_booking, setTimeBetBooking] = useState("");
  const [filterToday, setFilterToday] = useState(false);
  const user = useUser(token);
  const expandedRowRef = useRef(null);
  const { id } = props;
  const [filterUser, setFilterUser] = useState('');
  const [confList, setConfList] = useState([]);

  const handleDetailsClick = (booking) => {
    setExpandedRow(expandedRow === booking.id ? null : booking.id);
    setSelectedBooking(booking);
  };
  const handleFilterUserChange = (event) => {
    setFilterUser(event.target.value);
  };

  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [confirmationMessage, setConfirmationMessage] = useState('');
  const [confirmationAction, setConfirmationAction] = useState(null);

  const openConfirmationModal = (message, action) => {
    setConfirmationMessage(message);
    setConfirmationAction(() => action);
    setIsConfirmationModalOpen(true);
  };

  const closeConfirmationModal = () => {
    setIsConfirmationModalOpen(false);
    setConfirmationMessage('');
    setConfirmationAction(null);
  };

  const confirmAction = () => {
    if (confirmationAction) {
      confirmationAction();
    }
    closeConfirmationModal();
  };

  const filterBookingsForToday = (bookings) => {
    const today = new Date().toISOString().split('T')[0];
    return bookings.filter((booking) => {
      const bookingDate = new Date(booking.date);
      const bookingDateISOString = bookingDate.toISOString().split('T')[0];
      return bookingDateISOString === today;
    });
  };

  const loadBookings = async () => {
    try {
      if (user && id) {
        const settingsResponse = await getBookingSettings(id, token);
        const kids = settingsResponse.data.kids;
        const teen = settingsResponse.data.teen;
        const userEmail = settingsResponse.data.email;
        const time_bet_booking = settingsResponse.data.time_bet_booking;

        const employeesResponse = await getEmployeesList(token, id);

        const employeeIds = employeesResponse.data.map((employee) => employee.id);

        // Obtener bookings para cada ID de empleado
        const bookingPromises = employeeIds.map(async (employeeId) => {
          const res = await getAllBookings(employeeId, token);
          const confList = await ConfBookingListView(employeeId);

          // Mapear la lista de reservas y obtener el nombre de la configuración de reserva para cada reserva
          const bookingsWithData = res.data.map((booking) => {
            const bookingConfiguration = confList.find((conf) => conf.id === booking.booking_configuration);
            const bookingConfigurationName = bookingConfiguration ? bookingConfiguration.btn : 'Configuración no encontrada';

            return {
              ...booking,
              bookingConfigurationName: bookingConfigurationName
            };
          });

          return bookingsWithData;
        });

        // Obtener todos los bookings
        const bookingsResponses = await Promise.all(bookingPromises);

        // Concatenar todos los bookings en una sola lista
        const allBookings = bookingsResponses.flat();

        setKids(kids);
        setTeen(teen);
        setUserEmail(userEmail);
        setTimeBetBooking(time_bet_booking);

        if (allBookings && Array.isArray(allBookings)) {
          let filteredBookings = allBookings;

          // Aplicar el filtro para el día de hoy si está habilitado
          if (filterToday) {
            const today = new Date();
            today.setHours(today.getHours() - 4);
            const todayISOString = today.toISOString().split('T')[0];
            filteredBookings = filterBookingsForToday(filteredBookings, todayISOString);
          }

          const sortedBookings = allBookings.sort((a, b) => new Date(a.date) - new Date(b.date));

          const bookingsWithDateTime = sortedBookings.map((booking) => {
            const [date, time] = booking.date.split('T');

            const correspondingEmployee = employeesResponse.data.find(
              (employee) => employee.id === booking.customer_user
            );
            const employeeName = correspondingEmployee ? correspondingEmployee.username : '';
            const employeeImage = correspondingEmployee ? correspondingEmployee.profile.image : '';

            return {
              ...booking,
              date: date,
              time: time,
              employeeName: employeeName,
              employeeImage: employeeImage,
            };
          });

          setTableData(bookingsWithDateTime);
        } else {
          console.error('Invalid data format received:', allBookings);
        }
      } else {
        //console.error('El usuario aún no está cargado o no tiene un ID definido');
      }


    } catch (error) {
      console.error('Error al cargar reservas:', error.message);
    }
  };

  const formatTime = (time) => {
    const [hours, minutes] = time.split(':');
    return `${hours}:${minutes}`;
  };

  useEffect(() => {
    // Utilizar la función loadBookings
    loadBookings();

    if (expandedRowRef.current) {
      expandedRowRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  }, [token, expandedRow, user, filterToday]);

  useEffect(() => {
    const fetchConfList = async () => {
      try {
        const response = await ConfBookingListView(id, token);
        setConfList(response); // Almacenar la lista de configuraciones en el estado
      } catch (error) {
        console.error('Error al obtener la lista de configuraciones de reserva:', error.message);
      }
    };

    fetchConfList(); // Llamar a la función de obtención de la lista de configuraciones
  }, [id, token]);

  const getBookingConfigurationName = (bookingConfigurationId) => {
    const bookingConfig = confList.find((conf) => conf.id === bookingConfigurationId);
    return bookingConfig ? bookingConfig.btn : 'Configuración no encontrada';
  };

  const updateStatusBooking = async (bookingId, newStatus) => {
    try {
      const bookingToUpdate = tableData.find((booking) => booking.id === bookingId);

      if (bookingToUpdate && (newStatus === 1 || newStatus === 2)) {

        await updateBooking(bookingId, { status_booking: newStatus }, token);

        loadBookings();
      }
    } catch (error) {
      console.error('Error al actualizar el estado de la reserva:', error.message);
    }
  };

  const handleUpdateBooking = async (bookingId, updatedData) => {
    try {

      await updateBooking(bookingId, updatedData, token);
      loadBookings();
    } catch (error) {
      console.error('Error al actualizar la reserva:', error.message);
    }
  };

  const isBookingForToday = (booking) => {
    const today = new Date().toISOString().split('T')[0];
    const bookingDate = new Date(booking.date);
    const bookingDateISOString = bookingDate.toISOString().split('T')[0];
    return bookingDateISOString === today;
  };



  return (
    <div className="container mx-auto mt-2 p-6 bg-white rounded-md shadow-md">
      <div className="px-8 flex justify-between mb-6">
        <h2 className="font-medium text-lg min-w-0 whitespace-nowrap overflow-hidden text-ellipsis">
          Reservas
        </h2>
        <input
          type="text"
          value={filterUser}
          onChange={handleFilterUserChange}
          placeholder="Buscar"
          className="px-2 rounded-lg border border-neutral-800 focus:outline-none"
        />
        <button
          id="filter-today-button"
          className={`px-3 py-1 rounded text-white text-xs overflow-hidden font-bold ${filterToday ? 'bg-emerald-500' : 'bg-neutral-800'
            }`}
          onClick={() => {
            setFilterToday(!filterToday);
          }}
        >
          {filterToday ? 'Mostrar Todas' : 'Mostrar Hoy'}
        </button>

      </div>

      {tableData.length === 0 ? (
        <p className="text-center text-gray-500">No hay reservas disponibles.</p>
      ) : (
        <div className="table-container overflow-x-auto">
          <table className="min-w-full bg-white border border-neutral-800">
            <thead className="bg-neutral-800 text-white text-sm">
              <tr>
                <th className="py-2 px-4">Usuario</th>
                <th className="py-2 px-4">Nombre</th>
                <th className="py-2 px-4">Servicio</th>
                <th className="py-2 px-4">Fecha</th>

                {/*  <th className="py-2 px-4">Teléfono</th> */}
                <th className="py-2 px-4">Estado</th>
                <th className="py-2 px-4">Acciones</th>
              </tr>
            </thead>
            <tbody>
              {tableData
                .filter((booking) => {
                  // Aplicar el filtro por nombre de usuario
                  return (
                    booking.employeeName.toLowerCase().includes(filterUser.toLowerCase()) ||
                    booking.nombre.toLowerCase().includes(filterUser.toLowerCase())
                  );
                })
                .filter((booking) => {
                  // Verificar el estado de la reserva y si se debe mostrar para el día actual
                  return (
                    booking.status_booking !== 2 &&
                    booking.status_booking !== 4 &&
                    booking.status_booking !== 5 &&
                    (!filterToday || isBookingForToday(booking))
                  );
                })


                .map((booking) => {
                  return (
                    <React.Fragment key={booking.id}>

                      <tr key={booking.id} className="bg-white border-t border-gray-300 text-sm " onClick={() => handleDetailsClick(booking)}>

                        <td className="flex items-center justify-center"> {/* Añade la clase justify-center para centrar horizontalmente */}
                          <img
                            src={`${IMAGE_DOMAIN}${booking.employeeImage}`}
                            className="rounded-full h-12 w-12 mt-2"
                            alt={booking.employeeName}
                            title={booking.employeeName} // Aquí se establece el título para el globo de comentario
                          />
                        </td>

                        <td className="py-2 px-4 text-sm  font-semibold">
                          <p className="mt-1">{booking.nombre}</p>
                        </td>

                        <td className="py-2 px-4 text-sm">
                          {booking.bookingConfigurationName}
                        </td>

                        <td className="py-2 px-4">
                          <p>{booking.date}</p>
                          <strong>{formatTime(booking.time)}</strong>
                        </td>

                        <td className="py-2 px-4">
                          <button
                            id='estado'
                            className={`
                      px-3 py-1 rounded text-white text-xs overflow-hidden font-semibold
                      ${booking.status_booking === 0 ? 'bg-cyan-500' : ''}
                      ${booking.status_booking === 1 ? 'bg-emerald-500' : ''}
                      ${booking.status_booking === 2 ? 'bg-red-500' : ''}
                      ${booking.status_booking === 3 ? 'bg-orange-500' : ''}
                      ${booking.status_booking === 4 ? 'bg-purple-500' : ''}
                    `}
                            style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}
                          >
                            {booking.status_booking === 0 ? 'En Espera' : ''}
                            {booking.status_booking === 1 ? 'Aceptado' : ''}
                            {booking.status_booking === 2 ? 'Cancelado' : ''}
                            {booking.status_booking === 3 ? 'En Curso' : ''}
                            {booking.status_booking === 4 ? 'Terminado' : ''}
                          </button>
                        </td>

                        <td className="py-2 px-4 flex items-center justify-center space-x-1">
                          <button
                            id={`details-${booking.id}`}
                            className="flex items-center px-2 py-1 bg-blue-500 text-white text-xs rounded hover:bg-blue-600"
                            onClick={() => handleDetailsClick(booking)}>
                            <FaEye className="cursor-pointer text-white-500 text-sm mr-1" />
                            Ver
                          </button>
                          {booking.status_booking === 0 && (
                            <>
                              <button
                                id='aceptar'
                                className="px-2 py-1 bg-green-500 text-white rounded hover:bg-green-600"
                                onClick={() => openConfirmationModal(`¿Aceptando reserva, está seguro?`, () => updateStatusBooking(booking.id, 1))}>
                                <FaCheck className="cursor-pointer text-white-500 text-sm" />
                              </button>
                              <button
                                id='cancelar'
                                className="px-2 py-1 bg-red-500 text-white rounded hover:bg-red-600"
                                onClick={() => openConfirmationModal(`¿Cancelando reserva, está seguro?`, () => updateStatusBooking(booking.id, 2))}>
                                <FaBan className="cursor-pointer text-white-500 text-sm" />
                              </button>
                            </>
                          )}
                        </td>

                      </tr>
                      {expandedRow === booking.id && (
                        <tr ref={expandedRowRef}>
                          <td colSpan="9" className="bg-gray-200 p-4 justify-center">
                            <ButtonsForm phone={selectedBooking.phone} email={selectedBooking.email} formData={selectedBooking} userEmail={userEmail} time_bet_booking={time_bet_booking} />
                            <BookingEdit
                              booking={selectedBooking}
                              onUpdateBooking={handleUpdateBooking}
                              kids={kids}
                              teen={teen}
                              userEmail={userEmail}
                              formattedTime={formatTime(booking.time)} />
                          </td>
                        </tr>
                      )}
                    </React.Fragment>
                  );
                })
              }
            </tbody>
          </table>
        </div>
      )}
      <ConfirmationModal
        isOpen={isConfirmationModalOpen}
        message={confirmationMessage}
        onConfirm={confirmAction}
        onClose={closeConfirmationModal}
      />
      {isConfirmationModalOpen && (
        <div className="fixed inset-0 bg-black opacity-50" onClick={closeConfirmationModal}></div>
      )}
    </div>
  );
};
export { BookingTableAdmin };